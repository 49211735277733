import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import AuthModule from '@/store/modules/auth';
import RequestManagerModule from '@/common/axiosVuexExtender/storeModule/requestManager';
import CampaignModule from '@/store/modules/campaign';
import ProfileModule from '@/store/modules/profile';
import { RouterHelper, routerHelper } from '@/helpers/routerHelper';
import arm from '@/common/axiosVuexExtender/axiosRequestManager';
import ServerErrorsModule from '@/store/modules/serverErrors';
import LoginTypeEnum from '@/models/loginTypeEnum';

@Component
export default class BaseVue extends Vue {
  get isUsingMockedResponse() {
    return arm.isUsingMockedResponse;
  }

  get isDevMode() {
    return arm.isDevMode;
  }

  get isDevPpMode() {
    return arm.isDevPpMode;
  }

  get isDevDebugMode() {
    return arm.isDevDebugMode;
  }

  get isAthlete() {
    return (
      this.authModule.vLoginType === LoginTypeEnum.Athlete ||
      this.authModule.vLoginType === LoginTypeEnum.AgentParent
    );
  }

  get isSponsor() {
    return this.authModule.vLoginType === LoginTypeEnum.Sponsor;
  }

  get isFan() {
    return this.authModule.vLoginType === LoginTypeEnum.Fan;
  }

  get isBackoffice() {
    return this.authModule.vLoginType === LoginTypeEnum.Backoffice;
  }

  private _authModule: AuthModule | null = null;
  get authModule() {
    if (!this._authModule) {
      this._authModule = getModule(AuthModule, this.$store);
    }
    return this._authModule;
  }

  private _requestManagerModule: RequestManagerModule | null = null;
  get requestManagerModule() {
    if (!this._requestManagerModule) {
      this._requestManagerModule = getModule(RequestManagerModule, this.$store);
    }
    return this._requestManagerModule;
  }

  private _campaignModule: CampaignModule | null = null;
  get campaignModule() {
    if (!this._campaignModule) {
      this._campaignModule = getModule(CampaignModule, this.$store);
    }
    return this._campaignModule;
  }

  private _profileModule: ProfileModule | null = null;
  get profileModule() {
    if (!this._profileModule) {
      this._profileModule = getModule(ProfileModule, this.$store);
    }
    return this._profileModule;
  }

  private _routerHelper: RouterHelper | null = null;
  get routerHelper() {
    if (!this._routerHelper) {
      this._routerHelper = routerHelper(this.$router);
    }

    return this._routerHelper;
  }

  private _serverErrorsModule: ServerErrorsModule | null = null;
  get serverErrorsModule() {
    if (!this._serverErrorsModule) {
      this._serverErrorsModule = getModule(ServerErrorsModule, this.$store);
    }
    return this._serverErrorsModule;
  }

  get optionsHelper(): any {
    return (window as any)._anewicon_options;
  }

  async errorHandlingAsync(error: any) {
    this.requestManagerModule.resetState();
    if (await this.isNetworkErrorAsync(error)) {
      return;
    }

    if (error && error.response) {
      if (typeof error.response.status === 'number') {
        switch (error.response.status) {
          case 400:
            await this.resolveHttp400Async(error, error.response.data);
            break;

          case 401:
          case 440:
            await this.authModule.logOut(true);
            if (
              error &&
              error.config &&
              error.config.url &&
              error.config.url.startsWith('/backoffice')
            ) {
              this.routerHelper.goToBackofficeLogin();
            } else {
              this.routerHelper.goToLogin();
            }
            break;

          case 403:
            if (error.response.statusText) {
              alert(error.response.statusText);
            } else {
              alert('Attempted to perform an unauthorized operation.');
            }
            await this.authModule.logOut(true);
            break;

          case 404:
          case 500:
            alert(
              'An unexpected error occurred, please contact the system administrator.'
            );
            if (this.authModule.isAuthenticated) {
              this.routerHelper.goToDashboard();
            } else {
              this.routerHelper.goToLogin();
            }
            break;
        }
      }
    }
  }

  private async resolveHttp400Async(error: any, data: any) {
    const errMessages = this.getErrorResponseDataMessages(data);
    if (errMessages !== null) {
      this.serverErrorsModule.addErrors(errMessages);
      this.scrollToServerSideError();
    } else {
      await this.authModule.logOut(true);
      alert('Please contact system administrator.');
      if (
        error &&
        error.config &&
        error.config.url &&
        error.config.url.startsWith('/backoffice')
      ) {
        this.routerHelper.goToBackofficeLogin();
      } else {
        this.routerHelper.goToLogin();
      }
    }
  }

  public scrollToServerSideError(container?: any) {
    this.$nextTick(() => {
      const elSsErrorList = document.getElementsByClassName(
        'serverSideErrorList'
      );
      if (elSsErrorList && elSsErrorList.length > 0) {
        this.scrollTo(container, elSsErrorList[0]);
      }
    });
  }

  private async isNetworkErrorAsync(error: any) {
    if (error && error.message === 'Network Error') {
      await this.authModule.logOut(false);
      alert('Please contact system administrator.');
      if (
        error.config &&
        error.config.url &&
        error.config.url.startsWith('/backoffice')
      ) {
        this.routerHelper.goToBackofficeLogin();
      } else {
        this.routerHelper.goToLogin();
      }
      return true;
    }

    return false;
  }

  getErrorResponseDataMessages(data: any): Array<string> | null {
    if (!data) {
      return null;
    }
    if (data.constructor === Array) {
      return data;
    }
    if (typeof data === 'string') {
      return [data];
    }

    if (Object.keys(data).length !== 0 && data.constructor === Object) {
      let errors: Array<string> = [];
      for (const property in data) {
        if (Object.prototype.hasOwnProperty.call(data, property)) {
          const propValue = data[property];
          if (propValue !== null) {
            if (propValue.constructor === Array) {
              errors = errors.concat(propValue);
            } else {
              errors.push(propValue);
            }
          }
        }
      }
      return errors;
    }

    return null;
  }

  scrollToFirstError(container?: any, duration?: any, options?: any) {
    this.$nextTick(() => {
      const elements = document.getElementsByClassName('errorMessage');
      for (const el of elements) {
        const hel = el as HTMLElement;
        if (hel) {
          if (!this.isHidden(hel)) {
            let parent = hel.parentElement;
            while (parent) {
              if (parent.classList.contains('errorParentWrapper')) {
                break;
              }
              parent = parent.parentElement;
            }
            if (parent) {
              this.scrollTo(container, parent, duration, options);
            }
            return;
          }
        }
      }
    });
  }

  findAncestor(el: any, cls: string) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
  }

  private isHidden(el: HTMLElement | null): boolean {
    let result = false;
    if (el) {
      const style = window.getComputedStyle(el);
      result = style.display === 'none';
    }
    return result;
  }

  scrollTo(container?: any, target?: any, duration?: any, options?: any): void {
    if (Vue.prototype.$scrollTo) {
      duration = duration || 1000;
      options = options || {};
      if (options.offset === undefined) {
        options.offset = -10;
      }
      options.container = container || 'body';
      Vue.prototype.$scrollTo(target, duration, options);
    }
  }
}
