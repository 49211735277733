import { AxiosResponse, AxiosStatic } from 'axios';
import VueRouter from 'vue-router';
import { Store } from 'vuex';
import { getModule, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfigExtended } from './axiosRequestConfigExtended';
import RequestManagerModule from './storeModule/requestManager';
import { isDevMode, devForcePp, devForceDebug } from '@/common/appSettings';

class AxiosRequestManager {
  private static instance: AxiosRequestManager;
  private apiBaseURL = '';
  private useMockedResponse = false;
  private axios!: AxiosStatic;
  private store!: Store<any>;
  private router!: VueRouter;
  public defaultDelay = 1000;
  private resetState: (() => void) | null = null;

  public static get Instance() {
    return this.instance || (this.instance = new this());
  }

  get isDevMode() {
    return isDevMode;
  }

  get isUsingMockedResponse() {
    return this.useMockedResponse;
  }

  get isPpMode() {
    return !!this.router.currentRoute.query['pp'] || devForcePp;
  }

  get isDevPpMode() {
    return this.isDevMode && this.isPpMode;
  }

  get isDevDebugMode() {
    return (
      this.isDevMode &&
      (!!this.router.currentRoute.query['debug'] || devForceDebug)
    );
  }

  public configAxiosToStore<T extends VuexModule & { resetState(): void }>(
    apiBaseURL: string,
    useMockedResponse: boolean,
    axios: AxiosStatic,
    store: Store<any>,
    router: VueRouter,
    errorStoreType?: {
      new (...args: any[]): T;
    }
  ) {
    this.apiBaseURL = apiBaseURL;
    this.useMockedResponse = useMockedResponse;
    this.axios = axios;
    this.store = store;
    this.router = router;

    const rmm = getModule(RequestManagerModule, store);

    if (errorStoreType) {
      this.resetState = getModule(errorStoreType, store).resetState;
    }

    axios.interceptors.request.use(
      (config) => {
        rmm.addRequest((config as AxiosRequestConfigExtended).groupName);
        return config;
      },
      (error) => {
        if (!error.config) {
          rmm.removeRequest(
            (error.config as AxiosRequestConfigExtended).groupName
          );
        }
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        rmm.removeRequest(
          (response.config as AxiosRequestConfigExtended).groupName
        );
        return response;
      },
      (error) => {
        if (error.config) {
          rmm.removeRequest(
            (error.config as AxiosRequestConfigExtended).groupName
          );
        }
        return Promise.reject(error);
      }
    );

    router.beforeResolve((to: any, from: any, next: any) => {
      rmm.resetState();
      next();
    });
  }

  public get<T = any, R = AxiosResponse<T>>(
    url: string,
    mockedResponse: T,
    groupName?: string | undefined,
    delay?: number | undefined,
    config?: AxiosRequestConfigExtended,
    forceMocked = false
  ): Promise<R> {
    this.onResetState();
    if (this.useMockedResponse || forceMocked) {
      return this.mockAxiosResponse<T>(
        'GET',
        url,
        undefined,
        mockedResponse,
        groupName,
        delay,
        config
      ) as unknown as Promise<R>;
    } else {
      return this.axios.get(
        url,
        Object.assign<AxiosRequestConfigExtended, AxiosRequestConfigExtended>(
          config || {},
          {
            baseURL: this.apiBaseURL,
            groupName: groupName
          }
        )
      );
    }
  }

  public post<T = any, R = AxiosResponse<T>>(
    url: string,
    data: any,
    mockedResponse: T,
    groupName?: string | undefined,
    delay?: number | undefined,
    config?: AxiosRequestConfigExtended,
    event?: Event | null | undefined,
    forceMocked = false
  ): Promise<R> {
    this.onResetState();
    if (this.useMockedResponse || forceMocked) {
      return this.mockAxiosResponse<T>(
        'POST',
        url,
        data,
        mockedResponse,
        groupName,
        delay,
        config,
        event
      ) as unknown as Promise<R>;
    } else {
      return this.axios.post(
        url,
        data,
        Object.assign<AxiosRequestConfigExtended, AxiosRequestConfigExtended>(
          config || {},
          {
            baseURL: this.apiBaseURL,
            groupName: groupName
          }
        )
      );
    }
  }

  public put<T = any, R = AxiosResponse<T>>(
    url: string,
    data: any,
    mockedResponse: T,
    groupName?: string | undefined,
    delay?: number | undefined,
    config?: AxiosRequestConfigExtended,
    forceMocked = false
  ): Promise<R> {
    this.onResetState();
    if (this.useMockedResponse || forceMocked) {
      return this.mockAxiosResponse<T>(
        'PUT',
        url,
        data,
        mockedResponse,
        groupName,
        delay,
        config
      ) as unknown as Promise<R>;
    } else {
      return this.axios.put(
        url,
        data,
        Object.assign<AxiosRequestConfigExtended, AxiosRequestConfigExtended>(
          config || {},
          {
            baseURL: this.apiBaseURL,
            groupName: groupName
          }
        )
      );
    }
  }

  public delete<T = any, R = AxiosResponse<T>>(
    url: string,
    mockedResponse: T,
    groupName?: string | undefined,
    delay?: number | undefined,
    config?: AxiosRequestConfigExtended,
    forceMocked = false
  ): Promise<R> {
    this.onResetState();
    if (this.useMockedResponse || forceMocked) {
      return this.mockAxiosResponse<T>(
        'DELETE',
        url,
        undefined,
        mockedResponse,
        groupName,
        delay,
        config
      ) as unknown as Promise<R>;
    } else {
      return this.axios.delete(
        url,
        Object.assign<AxiosRequestConfigExtended, AxiosRequestConfigExtended>(
          config || {},
          {
            baseURL: this.apiBaseURL,
            groupName: groupName
          }
        )
      );
    }
  }

  private onResetState() {
    if (this.resetState) {
      this.resetState();
    }
  }

  private mockAxiosResponse<
    T extends { httpStatus?: number; httpStatusData?: string | string[] }
  >(
    method: string,
    url: string,
    data: any,
    response: T,
    groupName?: string | undefined,
    delay?: number | undefined,
    config?: AxiosRequestConfigExtended,
    event?: Event | null | undefined
  ) {
    this.printDebugMessage<T>(groupName, method, url, config, data, response);

    if (config && config.onUploadProgress && event) {
      const total = 20;
      const sleep = (value: number) =>
        new Promise((resolve) => setTimeout(resolve, value));
      const isInfinite = groupName === 'infiniteUploadingFile';

      // eslint-disable-next-line
      return new Promise<AxiosResponse<T>>(async (resolve) => {
        const rmm = getModule(RequestManagerModule, this.store);

        rmm.addRequest(groupName);

        if (config.onUploadProgress) {
          for (let i = 0; i <= total; i++) {
            if (isInfinite && i > total / 2) {
              break;
            }

            if (i === 0) {
              config.onUploadProgress(
                Object.assign(event, {
                  loaded: total * 0.005,
                  total,
                  lengthComputable: true
                })
              );
            } else {
              config.onUploadProgress(
                Object.assign(event, {
                  loaded: i,
                  total,
                  lengthComputable: true
                })
              );
            }
            await sleep(100);
          }
        }
        await sleep(1000);

        if (!isInfinite) {
          rmm.removeRequest(groupName);
          resolve({ data: response } as AxiosResponse<T>);
        }
      });
    } else {
      return new Promise<AxiosResponse<T>>((resolve, reject) => {
        const rmm = getModule(RequestManagerModule, this.store);

        rmm.addRequest(groupName);
        const axiosResponse = { data: response } as AxiosResponse<T>;

        if (delay === undefined) delay = this.defaultDelay;
        if (delay) {
          setTimeout(() => {
            rmm.removeRequest(groupName);

            if (response && response.httpStatus) {
              if (response.httpStatusData) {
                reject({
                  response: {
                    status: response.httpStatus,
                    data: response.httpStatusData
                  }
                });
              } else {
                reject({ response: { status: response.httpStatus } });
              }
            } else {
              resolve(axiosResponse);
            }
          }, delay);
        } else {
          rmm.removeRequest(groupName);

          if (response && response.httpStatus) {
            if (response.httpStatusData) {
              reject({
                response: {
                  status: response.httpStatus,
                  data: response.httpStatusData
                }
              });
            } else {
              reject({ response: { status: response.httpStatus } });
            }
          } else {
            resolve(axiosResponse);
          }
        }
      });
    }
  }

  public printDebugMessage<T>(
    groupName: string | undefined,
    method: string,
    url: string,
    config: AxiosRequestConfigExtended | undefined,
    data: any,
    response: T
  ) {
    if (this.isDevDebugMode) {
      let calculatedUrl = url;

      if (config && config.params) {
        if (method === 'GET') {
          calculatedUrl = `${calculatedUrl}?${config.params}`;
        }
      }

      let msg = `Mocked endpoint call (groupName: ${
        groupName || 'undefined'
      })\r\nURL (${method}): "${this.apiBaseURL}${calculatedUrl}"`;

      if (data) {
        msg += `\r\nPayload: ${JSON.stringify(data)}`;
      }

      msg += `\r\nResponse: ${JSON.stringify(response)}`;

      console.info(msg);
    }
  }
}

export default AxiosRequestManager.Instance;
