import CustomFileUpload from '@/models/customFileUpload';
import moment from 'moment';
import CampaignApplicationDetailsAthleteCriteriaViewModel, {
  mocked_campaignApplicationDetailsAthleteCriteriaViewModel_get
} from './applicationDetailsAthleteCriteriaViewModel';
import CampaignApplicationDetailsAthleteActivityViewModel, {
  mocked_campaignApplicationDetailsAthleteActivityViewModel_get
} from './applicationDetailsAthleteActivityViewModel';

export default interface CampaignApplicationDetailsAthleteViewModel {
  applicationId: number;
  athleteId: string;
  name: string;
  profileImage?: CustomFileUpload | null;
  lastStatusUpdateDate: Date;
  statusName: string;
  interestExpressed: boolean;
  criterias?: Array<CampaignApplicationDetailsAthleteCriteriaViewModel> | null;
  activities?: Array<CampaignApplicationDetailsAthleteActivityViewModel> | null;
}

export const mocked_campaignAthleteList_Success: Array<CampaignApplicationDetailsAthleteViewModel> =
  [
    {
      applicationId: 1,
      athleteId: '448de97a-ac0e-4fb0-8d15-5e52c9db0639',
      name: 'Lewis Hamilton',
      profileImage: new CustomFileUpload(
        undefined,
        'ccaf1f9a-1769-4f67-9fe9-f0b882677326',
        'default-profile.png',
        '0.08Mb',
        'https://localhost:7235/Image/Download/ccaf1f9a-1769-4f67-9fe9-f0b882677326'
      ),
      lastStatusUpdateDate: moment('2022-02-25T19:50:28.2858845').toDate(),
      statusName: 'Requesting Proposal',
      interestExpressed: false,
      criterias: mocked_campaignApplicationDetailsAthleteCriteriaViewModel_get,
      activities: mocked_campaignApplicationDetailsAthleteActivityViewModel_get
    }
  ];
