import moment from 'moment';

export default interface FanProfileViewModel {
  firstName?: string | null;
  surname?: string | null;
  dateOfBirth?: Date | null;
}

export const mocked_fanProfileViewModel_getProfile: FanProfileViewModel = {
  firstName: 'Mary',
  surname: 'Smith',
  dateOfBirth: moment('1999-01-11').toDate()
};
