export default interface CampaignApplicationDetailsAthleteCriteriaViewModel {
  type: CampaignApplicationDetailsAthleteCriteriaTypeEnum;
  valueIds?: Array<any> | null;
  valueNames?: Array<string> | null;
  numberOfFollowers?: number | null;
  criteriaMet: boolean;
}

export enum CampaignApplicationDetailsAthleteCriteriaTypeEnum {
  AthleteType = 1,
  Sport = 2,
  Location = 3,
  Gender = 4,
  SocialMedia = 5
}

export const mocked_campaignApplicationDetailsAthleteCriteriaViewModel_get: Array<CampaignApplicationDetailsAthleteCriteriaViewModel> =
  [
    {
      type: CampaignApplicationDetailsAthleteCriteriaTypeEnum.AthleteType,
      valueIds: [1, 2],
      valueNames: ['Type 1', 'Type 2'],
      numberOfFollowers: null,
      criteriaMet: true
    },
    {
      type: CampaignApplicationDetailsAthleteCriteriaTypeEnum.Sport,
      valueIds: [1, 2],
      valueNames: ['Sport 1', 'Sport 2'],
      numberOfFollowers: null,
      criteriaMet: false
    },
    {
      type: CampaignApplicationDetailsAthleteCriteriaTypeEnum.Location,
      valueIds: ['United Kingdom'],
      valueNames: ['United Kingdom'],
      numberOfFollowers: null,
      criteriaMet: true
    },
    {
      type: CampaignApplicationDetailsAthleteCriteriaTypeEnum.Gender,
      valueIds: [1, 2],
      valueNames: ['Gender 1', 'Gender 2'],
      numberOfFollowers: null,
      criteriaMet: true
    },
    {
      type: CampaignApplicationDetailsAthleteCriteriaTypeEnum.SocialMedia,
      valueIds: [1, 2],
      valueNames: ['Social Media 1', 'Social Media 2'],
      numberOfFollowers: 3000,
      criteriaMet: true
    }
  ];
