import SponsorProfileViewModel, {
  mocked_sponsorProfileViewModel_getProfile
} from '@/models/sponsor/profileViewModel';
import CustomFileUpload from '@/models/customFileUpload';

export default interface BackofficePortalUsersProfileSponsorViewModel
  extends SponsorProfileViewModel {
  profileImage?: CustomFileUpload | null;
}

export const mocked_BackofficePortalUsersProfileSponsorViewModel_getProfile: BackofficePortalUsersProfileSponsorViewModel =
  {
    profileImage: new CustomFileUpload(
      undefined,
      'ce6a5ad3-32be-4490-a036-d10b4607077c',
      'IMG_9358.JPG',
      '0.30Mb',
      'https://pbs.twimg.com/profile_images/464730364993232897/AquKUVAk_400x400.png'
    ),
    ...mocked_sponsorProfileViewModel_getProfile
  };
