import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueAxios from 'vue-axios';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import { localeCode } from './helpers/functionsHelper';
import vueMoment from 'vue-moment';
import moment from 'moment';
import VueScrollTo from 'vue-scrollto';
import arm from './common/axiosVuexExtender/axiosRequestManager';
import { apiBaseURL, useMockedResponse } from './common/appSettings';
import filters from '@/common/filters';
import ServerErrorsModule from '@/store/modules/serverErrors';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import 'vue-select/src/scss/vue-select.scss';
import './assets/style/style.scss';

moment.locale(localeCode);

Vue.config.productionTip = false;
Vue.use(Vuelidate);

axios.defaults.withCredentials = true;
Vue.use(VueAxios, axios);

Vue.use(vueMoment, { moment });
Vue.use(VueScrollTo);

arm.configAxiosToStore(
  apiBaseURL,
  !!useMockedResponse,
  axios,
  store,
  router,
  ServerErrorsModule
);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(filters);

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
