export default interface SponsorProfileViewModel {
  firstName?: string | null;
  surname?: string | null;
  role?: string | null;
  companyName?: string | null;
  companySizeId?: number | null;
  companySizeName?: string | null;
  website?: string | null;
  instagram?: string | null;
  industryId?: number | null;
  industryName?: string | null;
  hasPayPalConnection?: boolean | null;
}

export const mocked_sponsorProfileViewModel_getProfile: SponsorProfileViewModel =
  {
    firstName: 'Mary',
    surname: 'Smith',
    role: 'Founder',
    companyName: 'Company Test',
    companySizeId: 2,
    companySizeName: 'Company Size 2',
    website: 'www.companytest.com',
    instagram: 'companytestltd',
    industryId: 3,
    industryName: 'Industry 3',
    hasPayPalConnection: true
  };
