import arm from '@/common/axiosVuexExtender/axiosRequestManager';
import CustomFileUpload from '@/models/customFileUpload';
import LoginResponse, {
  mocked_loginAthlete_Success,
  mocked_loginAthleteIncomplete_Success,
  mocked_loginSponsor_Success,
  mocked_loginSponsorIncomplete_Success,
  mocked_loginFan_Success,
  mocked_login_Fail
} from '@/models/loginResponse';

class AuthService {
  private static instance: AuthService;

  public static get Instance() {
    return this.instance || (this.instance = new this());
  }

  public async loginAsync(email: string, password: string) {
    const response = await arm.post<LoginResponse>(
      '/account/login',
      { email, password },
      email.startsWith('fail')
        ? mocked_login_Fail
        : email.startsWith('ia')
        ? mocked_loginAthleteIncomplete_Success
        : email.startsWith('is')
        ? mocked_loginSponsorIncomplete_Success
        : email.startsWith('s')
        ? mocked_loginSponsor_Success
        : email.startsWith('f')
        ? mocked_loginFan_Success
        : mocked_loginAthlete_Success,
      'loginRequest'
    );

    const oRet = response.data;
    if (oRet.profileImage) {
      oRet.profileImage = new CustomFileUpload(
        undefined,
        oRet.profileImage.guid,
        oRet.profileImage.fileName,
        oRet.profileImage.fileSize,
        oRet.profileImage.src
      );
    }

    return oRet;
  }

  public async reloadDetailsAsync() {
    const oRet = (
      await arm.get<LoginResponse>(
        '/account/details',
        mocked_loginAthlete_Success,
        'reloadDetails'
      )
    ).data;

    if (oRet.profileImage) {
      oRet.profileImage = new CustomFileUpload(
        undefined,
        oRet.profileImage.guid,
        oRet.profileImage.fileName,
        oRet.profileImage.fileSize,
        oRet.profileImage.src
      );
    }

    return oRet;
  }

  public async logoutAsync() {
    await arm.post('/account/logout', null, null as any);
  }
}

export const authService = AuthService.Instance;
