import arm from '@/common/axiosVuexExtender/axiosRequestManager';
import { mocked_portalUserListItemViewModel_list } from '@/models/backoffice/portalUsers/listItemViewModel';
import CustomFileUpload from '@/models/customFileUpload';
import { mocked_BackofficePortalUsersProfileSponsorViewModel_getProfile } from '@/models/backoffice/portalUsers/profileSponsorViewModel';
import SponsorProfileViewModel from '@/models/sponsor/profileViewModel';
import AthleteProfileViewModel from '@/models/athlete/profileViewModel';
import { mocked_athleteProfileWithHeroViewModel_getProfile } from '@/models/athlete/profileWithHeroViewModel';
import FanProfileViewModel, {
  mocked_fanProfileViewModel_getProfile
} from '@/models/fan/profileViewModel';

class BackofficePortalUserService {
  private static instance: BackofficePortalUserService;

  public static get Instance() {
    return this.instance || (this.instance = new this());
  }

  async searchAsync(
    searchText: string,
    accountTypeId: number | null,
    currentPage: number,
    perPage: number
  ) {
    const params = new URLSearchParams();
    const encodedSearchText = encodeURIComponent(searchText);
    if ((encodedSearchText || '').trim()) {
      params.append('s', encodedSearchText);
    }
    params.append('p', currentPage.toString());
    params.append('ps', perPage.toString());
    if (accountTypeId !== null) {
      params.append('r', accountTypeId.toString());
    }

    const oRet = (
      await arm.get(
        '/backoffice/portalUsers',
        mocked_portalUserListItemViewModel_list,
        'searching',
        undefined,
        { params }
      )
    ).data;

    if (oRet && oRet.data && oRet.data.length) {
      oRet.data.forEach((x) => {
        x.profileImage = new CustomFileUpload(
          undefined,
          x.profileImage.guid,
          x.profileImage.fileName,
          x.profileImage.fileSize,
          x.profileImage.src
        );
      });
    }

    return oRet;
  }

  // TODO: Change all below to not use mocked

  async loadProfileAsync(id: string, addGroupName = false) {
    const oRet = (
      await arm.get(
        `/backoffice/portalUsers/sponsors/${id}`,
        mocked_BackofficePortalUsersProfileSponsorViewModel_getProfile,
        addGroupName ? 'loadProfile' : undefined,
        undefined,
        undefined,
        false
      )
    ).data;

    if (oRet.profileImage) {
      oRet.profileImage = new CustomFileUpload(
        undefined,
        oRet.profileImage.guid,
        oRet.profileImage.fileName,
        oRet.profileImage.fileSize,
        oRet.profileImage.src
      );
    }

    return oRet;
  }

  async loadAthleteProfileAsync(id: string, addGroupName = false) {
    const oRet = (
      await arm.get(
        `/backoffice/portalUsers/athletes/${id}`,
        mocked_athleteProfileWithHeroViewModel_getProfile,
        addGroupName ? 'loadProfile' : undefined,
        undefined,
        undefined,
        false
      )
    ).data;

    if (oRet.heroImage) {
      oRet.heroImage = new CustomFileUpload(
        undefined,
        oRet.heroImage.guid,
        oRet.heroImage.fileName,
        oRet.heroImage.fileSize,
        oRet.heroImage.src
      );
    }

    return oRet;
  }

  async loadFanProfileAsync(id: string, addGroupName = false) {
    const oRet = (
      await arm.get(
        `/backoffice/portalUsers/fans/${id}`,
        mocked_fanProfileViewModel_getProfile,
        addGroupName ? 'loadProfile' : undefined,
        undefined,
        undefined,
        false
      )
    ).data;

    return oRet;
  }

  async saveSponsorProfileAsync(
    id: string,
    data: SponsorProfileViewModel | null
  ) {
    await arm.put<any>(
      `/backoffice/portalUsers/sponsors/${id}`,
      data,
      undefined,
      'profilePut',
      undefined,
      undefined,
      false
    );
  }

  async saveAthleteProfileAsync(
    id: string,
    data: AthleteProfileViewModel | null
  ) {
    await arm.put<any>(
      `/backoffice/portalUsers/athletes/${id}`,
      data,
      undefined,
      'profilePut',
      undefined,
      undefined,
      false
    );
  }

  async saveFanProfileAsync(id: string, data: FanProfileViewModel | null) {
    await arm.put<any>(
      `/backoffice/portalUsers/fans/${id}`,
      data,
      undefined,
      'profilePut',
      undefined,
      undefined,
      false
    );
  }

  async setProfileImageAsync(id: string, newImage?: CustomFileUpload | null) {
    await arm.post(
      `/backoffice/portalUsers/setProfileImage/${decodeURIComponent(id)}`,
      newImage,
      undefined,
      'saveProfileImage',
      undefined,
      undefined,
      undefined,
      true
    );
  }
}
export const backofficePortalUserService = BackofficePortalUserService.Instance;
