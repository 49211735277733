














































































































import BaseVue from '@/common/baseVue';
import { Component, Watch } from 'vue-property-decorator';
import NavBar from '@/components/NavBar.vue';
import NavBarMobile from '@/components/NavBarMobile.vue';
import ServerErrorList from '@/components/ServerErrorList.vue';
import CustomButton from '@/components/Controls/CustomButton.vue';

@Component({
  components: {
    NavBar,
    NavBarMobile,
    ServerErrorList,
    CustomButton
  }
})
export default class App extends BaseVue {
  mobileNavIsOpen = false;
  loaded = false;
  get isLoading() {
    return this.requestManagerModule.isLoading();
  }

  get isAthletePublicPage() {
    return this.$route.meta?.athletePublicPage;
  }

  openNav() {
    this.mobileNavIsOpen = true;
    document.body.classList.add('navbar-open');
  }

  closeNav() {
    this.mobileNavIsOpen = false;
    document.body.classList.remove('navbar-open');
  }

  @Watch('$route')
  routerHandled() {
    this.loaded = true;
    this.closeNav();
  }

  mounted() {
    this.closeNav();
    setTimeout(this.routerHandled, 500);
  }

  get showMenu() {
    if (!this.$route.name) {
      return false;
    }
    return !this.$route.meta?.hideMenu;
  }

  get isSignUpPage() {
    return this.$route.name === 'signUp';
  }

  get hasAnyServerSideError() {
    return this.serverErrorsModule.hasAnyServerSideError;
  }

  @Watch('authModule.theme', { immediate: true })
  setTheme() {
    document
      .getElementsByTagName('html')[0]
      .setAttribute('data-theme', this.authModule.theme);
  }
}
