import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import CampaignNewViewModel, {
  CampaignSocialMediaDeliverable,
  CampaignAppearanceDeliverable,
  CampaignBaseDeliverable
} from '@/models/campaign/newViewModel';
import { sponsorCampaignService } from '@/services/sponsor/campaign.service';
import Vue from 'vue';

@Module({ namespaced: true, name: 'CampaignModule' })
export default class CampaignModule extends VuexModule {
  vCampaignNewViewModel: CampaignNewViewModel | null = null;
  vSponsorCompanyName = '';

  get campaignNewViewModel() {
    return this.vCampaignNewViewModel;
  }

  get sponsorCompanyName() {
    return this.vSponsorCompanyName;
  }

  @Mutation
  mSponsorCompanyName(payload: string) {
    this.vSponsorCompanyName = payload;
  }

  @Mutation
  resetState() {
    this.vCampaignNewViewModel = null;
    this.vSponsorCompanyName = '';
  }

  @Mutation
  mInsertUpdateSocialMediaDeliverable(payload: CampaignSocialMediaDeliverable) {
    if (!this.vCampaignNewViewModel) {
      this.vCampaignNewViewModel = {};
    }

    if (!this.vCampaignNewViewModel.socialMediaDeliverables) {
      this.vCampaignNewViewModel.socialMediaDeliverables = [];
    }

    const idx = this.vCampaignNewViewModel.socialMediaDeliverables.findIndex(
      (x) =>
        payload.id !== null && payload.id !== undefined && x.id === payload.id
    );
    if (idx >= 0) {
      this.vCampaignNewViewModel.socialMediaDeliverables[idx] = payload;
    } else {
      this.vCampaignNewViewModel.socialMediaDeliverables.push(payload);
    }

    Vue.set(
      this.vCampaignNewViewModel,
      'socialMediaDeliverables',
      this.vCampaignNewViewModel.socialMediaDeliverables
    );
  }

  @Mutation
  mRemoveSocialMediaDeliverableById(id: number) {
    if (!this.vCampaignNewViewModel) {
      this.vCampaignNewViewModel = {};
    }

    if (!this.vCampaignNewViewModel.socialMediaDeliverables) {
      this.vCampaignNewViewModel.socialMediaDeliverables = [];
    }

    const idx = this.vCampaignNewViewModel.socialMediaDeliverables.findIndex(
      (x) => x.id === id
    );
    if (idx >= 0) {
      this.vCampaignNewViewModel.socialMediaDeliverables.splice(idx, 1);
      Vue.set(
        this.vCampaignNewViewModel,
        'socialMediaDeliverables',
        this.vCampaignNewViewModel.socialMediaDeliverables
      );
    }
  }

  @Mutation
  mInsertUpdateAppearanceDeliverable(payload: CampaignAppearanceDeliverable) {
    if (!this.vCampaignNewViewModel) {
      this.vCampaignNewViewModel = {};
    }

    if (!this.vCampaignNewViewModel.appearanceDeliverables) {
      this.vCampaignNewViewModel.appearanceDeliverables = [];
    }

    const idx = this.vCampaignNewViewModel.appearanceDeliverables.findIndex(
      (x) =>
        payload.id !== null && payload.id !== undefined && x.id === payload.id
    );
    if (idx >= 0) {
      this.vCampaignNewViewModel.appearanceDeliverables[idx] = payload;
    } else {
      this.vCampaignNewViewModel.appearanceDeliverables.push(payload);
    }

    Vue.set(
      this.vCampaignNewViewModel,
      'appearanceDeliverables',
      this.vCampaignNewViewModel.appearanceDeliverables
    );
  }

  @Mutation
  mRemoveAppearanceDeliverableById(id: number) {
    if (!this.vCampaignNewViewModel) {
      this.vCampaignNewViewModel = {};
    }

    if (!this.vCampaignNewViewModel.appearanceDeliverables) {
      this.vCampaignNewViewModel.appearanceDeliverables = [];
    }

    const idx = this.vCampaignNewViewModel.appearanceDeliverables.findIndex(
      (x) => x.id === id
    );
    if (idx >= 0) {
      this.vCampaignNewViewModel.appearanceDeliverables.splice(idx, 1);
      Vue.set(
        this.vCampaignNewViewModel,
        'appearanceDeliverables',
        this.vCampaignNewViewModel.appearanceDeliverables
      );
    }
  }

  @Mutation
  mInsertUpdateLogoPlacementDeliverable(payload: CampaignBaseDeliverable) {
    if (!this.vCampaignNewViewModel) {
      this.vCampaignNewViewModel = {};
    }

    if (!this.vCampaignNewViewModel.logoPlacementDeliverables) {
      this.vCampaignNewViewModel.logoPlacementDeliverables = [];
    }

    const idx = this.vCampaignNewViewModel.logoPlacementDeliverables.findIndex(
      (x) =>
        payload.id !== null && payload.id !== undefined && x.id === payload.id
    );
    if (idx >= 0) {
      this.vCampaignNewViewModel.logoPlacementDeliverables[idx] = payload;
    } else {
      this.vCampaignNewViewModel.logoPlacementDeliverables.push(payload);
    }

    Vue.set(
      this.vCampaignNewViewModel,
      'logoPlacementDeliverables',
      this.vCampaignNewViewModel.logoPlacementDeliverables
    );
  }

  @Mutation
  mRemoveLogoPlacementDeliverableById(id: number) {
    if (!this.vCampaignNewViewModel) {
      this.vCampaignNewViewModel = {};
    }

    if (!this.vCampaignNewViewModel.logoPlacementDeliverables) {
      this.vCampaignNewViewModel.logoPlacementDeliverables = [];
    }

    const idx = this.vCampaignNewViewModel.logoPlacementDeliverables.findIndex(
      (x) => x.id === id
    );
    if (idx >= 0) {
      this.vCampaignNewViewModel.logoPlacementDeliverables.splice(idx, 1);
      Vue.set(
        this.vCampaignNewViewModel,
        'logoPlacementDeliverables',
        this.vCampaignNewViewModel.logoPlacementDeliverables
      );
    }
  }

  @Mutation
  mInsertUpdateProductFeedbackDeliverable(payload: CampaignBaseDeliverable) {
    if (!this.vCampaignNewViewModel) {
      this.vCampaignNewViewModel = {};
    }

    if (!this.vCampaignNewViewModel.productFeedbackDeliverables) {
      this.vCampaignNewViewModel.productFeedbackDeliverables = [];
    }

    const idx =
      this.vCampaignNewViewModel.productFeedbackDeliverables.findIndex(
        (x) =>
          payload.id !== null && payload.id !== undefined && x.id === payload.id
      );
    if (idx >= 0) {
      this.vCampaignNewViewModel.productFeedbackDeliverables[idx] = payload;
    } else {
      this.vCampaignNewViewModel.productFeedbackDeliverables.push(payload);
    }

    Vue.set(
      this.vCampaignNewViewModel,
      'productFeedbackDeliverables',
      this.vCampaignNewViewModel.productFeedbackDeliverables
    );
  }

  @Mutation
  mRemoveProductFeedbackDeliverableById(id: number) {
    if (!this.vCampaignNewViewModel) {
      this.vCampaignNewViewModel = {};
    }

    if (!this.vCampaignNewViewModel.productFeedbackDeliverables) {
      this.vCampaignNewViewModel.productFeedbackDeliverables = [];
    }

    const idx =
      this.vCampaignNewViewModel.productFeedbackDeliverables.findIndex(
        (x) => x.id === id
      );
    if (idx >= 0) {
      this.vCampaignNewViewModel.productFeedbackDeliverables.splice(idx, 1);
      Vue.set(
        this.vCampaignNewViewModel,
        'productFeedbackDeliverables',
        this.vCampaignNewViewModel.productFeedbackDeliverables
      );
    }
  }

  @Mutation
  mInsertUpdateOtherDeliverable(payload: CampaignBaseDeliverable) {
    if (!this.vCampaignNewViewModel) {
      this.vCampaignNewViewModel = {};
    }

    if (!this.vCampaignNewViewModel.otherDeliverables) {
      this.vCampaignNewViewModel.otherDeliverables = [];
    }

    const idx = this.vCampaignNewViewModel.otherDeliverables.findIndex(
      (x) =>
        payload.id !== null && payload.id !== undefined && x.id === payload.id
    );
    if (idx >= 0) {
      this.vCampaignNewViewModel.otherDeliverables[idx] = payload;
    } else {
      this.vCampaignNewViewModel.otherDeliverables.push(payload);
    }

    Vue.set(
      this.vCampaignNewViewModel,
      'otherDeliverables',
      this.vCampaignNewViewModel.otherDeliverables
    );
  }

  @Mutation
  mRemoveOtherDeliverableById(id: number) {
    if (!this.vCampaignNewViewModel) {
      this.vCampaignNewViewModel = {};
    }

    if (!this.vCampaignNewViewModel.otherDeliverables) {
      this.vCampaignNewViewModel.otherDeliverables = [];
    }

    const idx = this.vCampaignNewViewModel.otherDeliverables.findIndex(
      (x) => x.id === id
    );
    if (idx >= 0) {
      this.vCampaignNewViewModel.otherDeliverables.splice(idx, 1);
      Vue.set(
        this.vCampaignNewViewModel,
        'otherDeliverables',
        this.vCampaignNewViewModel.otherDeliverables
      );
    }
  }

  @Mutation
  mSave(payload: CampaignNewViewModel | null) {
    this.vCampaignNewViewModel = Object.assign(
      this.vCampaignNewViewModel || {},
      payload
    );
  }

  @Action({ commit: 'mSave', rawError: true })
  async getByIdAsync(id?: number | null) {
    const name = await sponsorCampaignService.getLoggedUserCompanyName();
    this.context.commit('mSponsorCompanyName', name);
    return await sponsorCampaignService.getDraftAsync(id);
  }

  @Action({ commit: 'mInsertUpdateSocialMediaDeliverable', rawError: true })
  async insertUpdateSocialMediaDeliverableAsync(
    payload: CampaignSocialMediaDeliverable
  ) {
    if (this.vCampaignNewViewModel?.id) {
      payload.id =
        await sponsorCampaignService.insertUpdateDeliverableSocialMediaAsync(
          this.vCampaignNewViewModel.id,
          payload
        );
      return payload;
    }
  }

  @Action({ commit: 'mRemoveSocialMediaDeliverableById', rawError: true })
  async removeSocialMediaDeliverableByIdAsync(id: number) {
    await sponsorCampaignService.removeDeliverableSocialMediaByIdAsync(id);
    return id;
  }

  @Action({ commit: 'mInsertUpdateAppearanceDeliverable', rawError: true })
  async insertUpdateAppearanceDeliverableAsync(
    payload: CampaignAppearanceDeliverable
  ) {
    if (this.vCampaignNewViewModel?.id) {
      payload.id =
        await sponsorCampaignService.insertUpdateDeliverableAppearanceAsync(
          this.vCampaignNewViewModel.id,
          payload
        );
      return payload;
    }
  }

  @Action({ commit: 'mRemoveAppearanceDeliverableById', rawError: true })
  async removeAppearanceDeliverableByIdAsync(id: number) {
    await sponsorCampaignService.removeDeliverableAppearanceByIdAsync(id);
    return id;
  }

  @Action({ commit: 'mInsertUpdateLogoPlacementDeliverable', rawError: true })
  async insertUpdateLogoPlacementDeliverableAsync(
    payload: CampaignBaseDeliverable
  ) {
    if (this.vCampaignNewViewModel?.id) {
      payload.id =
        await sponsorCampaignService.insertUpdateDeliverableLogoPlacementAsync(
          this.vCampaignNewViewModel.id,
          payload
        );
      return payload;
    }
  }

  @Action({ commit: 'mRemoveLogoPlacementDeliverableById', rawError: true })
  async removeLogoPlacementDeliverableByIdAsync(id: number) {
    await sponsorCampaignService.removeDeliverableLogoPlacementByIdAsync(id);
    return id;
  }

  @Action({ commit: 'mInsertUpdateProductFeedbackDeliverable', rawError: true })
  async insertUpdateProductFeedbackDeliverableAsync(
    payload: CampaignBaseDeliverable
  ) {
    if (this.vCampaignNewViewModel?.id) {
      payload.id =
        await sponsorCampaignService.insertUpdateDeliverableProductFeedbackAsync(
          this.vCampaignNewViewModel.id,
          payload
        );
      return payload;
    }
  }

  @Action({ commit: 'mRemoveProductFeedbackDeliverableById', rawError: true })
  async removeProductFeedbackDeliverableByIdAsync(id: number) {
    await sponsorCampaignService.removeDeliverableProductFeedbackByIdAsync(id);
    return id;
  }

  @Action({ commit: 'mInsertUpdateOtherDeliverable', rawError: true })
  async insertUpdateOtherDeliverableAsync(payload: CampaignBaseDeliverable) {
    if (this.vCampaignNewViewModel?.id) {
      payload.id =
        await sponsorCampaignService.insertUpdateDeliverableOtherAsync(
          this.vCampaignNewViewModel.id,
          payload
        );
      return payload;
    }
  }

  @Action({ commit: 'mRemoveOtherDeliverableById', rawError: true })
  async removeOtherDeliverableByIdAsync(id: number) {
    await sponsorCampaignService.removeDeliverableOtherByIdAsync(id);
    return id;
  }

  @Action({ rawError: true })
  async saveAsync(payload: {
    model: CampaignNewViewModel | null;
    sendHttp: boolean;
    groupName: string;
  }) {
    this.context.commit('mSave', payload.model);
    if (payload.sendHttp) {
      if (this.campaignNewViewModel?.id === undefined) {
        const campaignId = await sponsorCampaignService.insertAsync(
          this.campaignNewViewModel,
          payload.groupName
        );
        this.context.commit('mSave', { id: campaignId });
      } else {
        await sponsorCampaignService.updateAsync(
          this.campaignNewViewModel.id,
          this.campaignNewViewModel,
          payload.groupName
        );
      }
    }
  }

  @Action({ rawError: true })
  async saveAsDraftAsync(groupName: string) {
    if (this.campaignNewViewModel?.id === undefined) {
      await sponsorCampaignService.insertAsync(
        this.campaignNewViewModel,
        groupName
      );
    } else {
      await sponsorCampaignService.updateAsync(
        this.campaignNewViewModel.id,
        this.campaignNewViewModel,
        groupName
      );
    }
  }

  @Action({ rawError: true })
  async submitStoredItemAsync() {
    if (this.vCampaignNewViewModel?.id) {
      return (
        await sponsorCampaignService.submitAsync(this.vCampaignNewViewModel.id)
      ).data;
    }
  }
}
