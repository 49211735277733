















import BaseVue from '@/common/baseVue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ServerErrorList extends BaseVue {
  @Prop({ default: () => [] }) private errors!: Array<string>;
}
