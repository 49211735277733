













































































































































































































































































































































































































import BaseVue from '@/common/baseVue';
import LoginTypeEnum from '@/models/loginTypeEnum';
import { Component } from 'vue-property-decorator';

@Component
export default class NavBar extends BaseVue {
  showAsDisabled(routeName: string) {
    const route = this.$router.resolve({ name: routeName });
    return (
      !this.authModule.hasCompletedProfile &&
      !route.route.meta?.allowIncompleteProfile
    );
  }

  get isAgentParent() {
    return this.authModule.loginType === LoginTypeEnum.AgentParent;
  }

  get nameToBeDisplayedInPortal() {
    if (this.isAgentParent) {
      return this.authModule.agentParentFirstName;
    }

    return this.authModule.nameToBeDisplayedInPortal;
  }

  get imgSrc() {
    return this.authModule.profileImage?.src || '';
  }

  toggleLightDarkMode() {
    this.authModule.toggleTheme();
  }

  goToDashboard() {
    if (this.isBackoffice) {
      this.routerHelper.goToBackofficeDashboard();
    } else {
      this.routerHelper.goToDashboard();
    }
  }

  async logoutSend() {
    const wasBackoffice = this.isBackoffice;
    try {
      await this.authModule.logOut(true);
    } finally {
      if (wasBackoffice) {
        this.routerHelper.goToBackofficeLogin();
      } else {
        this.routerHelper.goToLogin();
      }
    }
  }
}
