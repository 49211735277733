import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { authService } from '@/services/auth.service';
import { backofficeAuthService } from '@/services/backoffice/auth.service';
import LoginResponse from '@/models/loginResponse';
import LoginTypeEnum from '@/models/loginTypeEnum';
import { athleteProfileService } from '@/services/athlete/profile.service';
import { sponsorProfileService } from '@/services/sponsor/profile.service';
import { fanProfileService } from '@/services/fan/profile.service';
import CustomFileUpload from '@/models/customFileUpload';

@Module({ namespaced: true, name: 'AuthModule' })
export default class AuthModule extends VuexModule {
  vIsAuthenticated = false;
  vNameToBeDisplayedInPortal?: string | null = '';
  vFullName?: string | null = '';
  vAgentParentFirstName?: string | null = '';
  vTheme = 'light';
  vProfileImage?: CustomFileUpload | null = null;
  vHasCompletedProfile?: boolean | null = null;
  vLoginType: number | null = null;

  get isAuthenticated() {
    return this.vIsAuthenticated;
  }

  get nameToBeDisplayedInPortal() {
    return this.vNameToBeDisplayedInPortal;
  }

  get fullName() {
    return this.vFullName;
  }

  get agentParentFirstName() {
    return this.vAgentParentFirstName;
  }

  get theme() {
    return this.vTheme;
  }

  get profileImage() {
    return this.vProfileImage;
  }

  get hasCompletedProfile() {
    return !!this.vHasCompletedProfile;
  }

  get loginType() {
    return this.vLoginType || 0;
  }

  @Mutation
  setNameToBeDisplayedInPortal(payload: {
    nameToBeDisplayedInPortal?: string | null;
  }) {
    this.vNameToBeDisplayedInPortal = payload.nameToBeDisplayedInPortal;
  }

  @Mutation
  setTheme(theme: string) {
    this.vTheme = theme;
  }

  @Mutation
  setImageUrl(profileImage?: CustomFileUpload | null) {
    this.vProfileImage = profileImage;
  }

  @Mutation
  profileCompleted() {
    this.vHasCompletedProfile = true;
  }

  @Mutation
  mLogIn(payload: LoginResponse) {
    this.vIsAuthenticated = payload.success;
    this.vNameToBeDisplayedInPortal = payload.nameToBeDisplayedInPortal;
    this.vFullName = payload.fullName;
    this.vAgentParentFirstName = payload.agentParentFirstName;
    this.vTheme = payload.theme || 'light';
    this.vProfileImage = payload.profileImage;
    this.vHasCompletedProfile = payload.hasCompletedProfile;
    if (payload.success && payload.loginType !== undefined) {
      this.vLoginType = payload.loginType;
    } else {
      this.vLoginType = null;
    }
  }

  @Mutation
  mReloadDetails(payload: LoginResponse) {
    console.log(payload);
    this.vNameToBeDisplayedInPortal = payload.nameToBeDisplayedInPortal;
    this.vFullName = payload.fullName;
    this.vAgentParentFirstName = payload.agentParentFirstName;
    this.vProfileImage = payload.profileImage;
    this.vHasCompletedProfile = payload.hasCompletedProfile;
  }

  @Mutation
  mLogOut() {
    this.vIsAuthenticated = false;
    this.vNameToBeDisplayedInPortal = null;
    this.vFullName = null;
    this.vAgentParentFirstName = null;
    this.vTheme = 'light';
    this.vProfileImage = null;
  }

  @Action({ commit: 'mLogOut', rawError: true })
  async logOut(sendLogout: boolean) {
    /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
    try {
      if (sendLogout) {
        if (this.vLoginType === (LoginTypeEnum.Backoffice as number)) {
          await backofficeAuthService.logoutAsync();
        } else {
          await authService.logoutAsync();
        }
      }
    } catch (err) {
    } finally {
      this.context.commit('RequestManagerModule/resetState', undefined, {
        root: true
      });

      this.context.commit('ServerErrorsModule/resetState', undefined, {
        root: true
      });
    }
  }

  @Action({ commit: 'mLogIn', rawError: true })
  async login(credentials: { email: string; password: string }) {
    return await authService.loginAsync(
      credentials.email,
      credentials.password
    );
  }

  @Action({ commit: 'mLogIn', rawError: true })
  async loginBackofficeAsync(credentials: { email: string; password: string }) {
    return await backofficeAuthService.loginAsync(
      credentials.email,
      credentials.password
    );
  }

  @Action({ commit: 'mReloadDetails', rawError: true })
  async reloadDetailsAsync() {
    return await authService.reloadDetailsAsync();
  }

  @Action({ commit: 'setTheme', rawError: true })
  toggleTheme() {
    let theme = 'light';
    if (this.vTheme === 'light') {
      theme = 'dark';
    }

    switch (this.vLoginType) {
      case LoginTypeEnum.Athlete:
        athleteProfileService.setThemeAsync(theme);
        break;
      case LoginTypeEnum.Sponsor:
        sponsorProfileService.setThemeAsync(theme);
        break;
      case LoginTypeEnum.Fan:
        fanProfileService.setThemeAsync(theme);
        break;
    }

    return theme;
  }
}
