import CampaignCardViewModel from '@/models/campaign/cardViewModel';
import moment from 'moment';
import CustomFileUpload from '../customFileUpload';
import CampaignApplicationDetailsAthleteViewModel, {
  mocked_campaignAthleteList_Success
} from './applicationDetailsAthleteViewModel';

export default interface CampaignApplicationDetailsViewModel
  extends CampaignCardViewModel {
  status?: string | null;
  createDate: Date | null;
  athletes?: Array<CampaignApplicationDetailsAthleteViewModel> | null;
}

export const mocked_get_Success: CampaignApplicationDetailsViewModel = {
  //#region Base class properties
  id: 1,
  title: 'My Running Campaign Title',
  description: 'My running campaign description',
  athleteTypeIds: [1, 2],
  athleteTypeNames: ['Athlete Type 1', 'Athlete Type 2'],
  socialNetworkIds: [1],
  socialNetworkNames: ['Instagram'],
  genderIds: [1, 3],
  genderNames: ['Agender', 'Androgynous'],
  locations: ['Ireland', 'United Kingdom'],
  minimumFollowingRequired: true,
  minimumFollowing: 301,
  cashPayment: 1235.74,
  productPayment: null,
  equityPercentage: 23.0,
  coverImage: new CustomFileUpload(
    undefined,
    '69572a76-43ce-4d15-8c04-1464f07315fb',
    '_example3.jpg',
    '0.04Mb',
    'https://localhost:7235/Image/Download/69572a76-43ce-4d15-8c04-1464f07315fb'
  ),
  //#endregion

  status: 'Submitted',
  createDate: moment('2022-02-15T18:26:45.2438868').toDate(),
  athletes: mocked_campaignAthleteList_Success
};
