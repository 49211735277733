import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import Login from '@/views/Login.vue';
import { getModule } from 'vuex-module-decorators';
import AuthModule from '@/store/modules/auth';
import ServerErrorsModule from '@/store/modules/serverErrors';
import store from '@/store';
import LoginTypeEnum from '@/models/loginTypeEnum';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: { allowAnonymous: true, hideMenu: true }
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ '@/views/ForgotPassword.vue'
      ),
    meta: { allowAnonymous: true, hideMenu: true }
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ '@/views/ResetPassword.vue'
      ),
    meta: { allowAnonymous: true, hideMenu: true }
  },
  {
    path: '/sign-up',
    name: 'signUp',
    component: () =>
      import(/* webpackChunkName: "signup" */ '@/views/SignUp.vue'),
    meta: { allowAnonymous: true, hideMenu: true }
  },
  {
    path: '/confirm-email',
    name: 'confirmEmail',
    component: () =>
      import(/* webpackChunkName: "confirmemail" */ '@/views/ConfirmEmail.vue'),
    meta: { allowAnonymous: true, hideMenu: true }
  },
  {
    path: '/athlete/social-feed',
    name: 'athleteSocialFeed',
    component: () =>
      import(
        /* webpackChunkName: "athleteSocialFeed" */ '@/views/Athlete/Feeds/Index.vue'
      ),
    meta: { onlyLoginTypes: [LoginTypeEnum.Athlete, LoginTypeEnum.AgentParent] }
  },
  {
    path: '/athlete/social',
    name: 'athleteSocial',
    component: () =>
      import(
        /* webpackChunkName: "athleteSocial" */ '@/views/Athlete/Social/Index.vue'
      ),
    meta: { onlyLoginTypes: [LoginTypeEnum.Athlete, LoginTypeEnum.AgentParent] }
  },
  {
    path: '/athlete/:id(.+)',
    name: 'publicAthlete',
    component: () =>
      import(
        /* webpackChunkName: "publicAthlete" */ '@/views/Athlete/PublicView.vue'
      ),
    meta: { allowAnonymous: true, athletePublicPage: true }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Index.vue'),
    meta: {
      allowIncompleteProfile: true,
      onlyLoginTypes: [
        LoginTypeEnum.Athlete,
        LoginTypeEnum.AgentParent,
        LoginTypeEnum.Sponsor
      ]
    }
  },
  {
    path: '/social-feed',
    name: 'sponsorSocialFeed',
    component: () =>
      import(
        /* webpackChunkName: "sponsorSocialFeed" */ '@/views/Sponsor/Feeds/Index.vue'
      ),
    meta: { onlyLoginTypes: [LoginTypeEnum.Sponsor] }
  },
  {
    path: '/my-applications',
    name: 'myApplications',
    component: () =>
      import(
        /* webpackChunkName: "myApplications" */ '@/views/MyApplications.vue'
      ),
    meta: { onlyLoginTypes: [LoginTypeEnum.Athlete, LoginTypeEnum.AgentParent] }
  },
  {
    path: '/my-applications/:id(\\d+)',
    name: 'myApplicationsView',
    component: () =>
      import(
        /* webpackChunkName: "myApplicationsView" */ '@/views/Athlete/Applications/View.vue'
      ),
    meta: { onlyLoginTypes: [LoginTypeEnum.Athlete, LoginTypeEnum.AgentParent] }
  },
  {
    path: '/browse-campaigns/view/:id(\\d+)',
    name: 'athleteCampaignView',
    component: () =>
      import(
        /* webpackChunkName: "athleteCampaignView" */ '@/views/Athlete/Campaigns/View.vue'
      ),
    meta: { onlyLoginTypes: [LoginTypeEnum.Athlete, LoginTypeEnum.AgentParent] }
  },
  {
    path: '/campaigns',
    name: 'campaignsIndex',
    component: () =>
      import(
        /* webpackChunkName: "campaignsIndex" */ '@/views/Campaigns/Index.vue'
      ),
    meta: { onlyLoginTypes: [LoginTypeEnum.Sponsor] }
  },
  {
    path: '/campaigns/new/:id(\\d+)?',
    name: 'campaignsNew',
    component: () =>
      import(
        /* webpackChunkName: "campaignsNew" */ '@/views/Campaigns/New.vue'
      ),
    meta: { onlyLoginTypes: [LoginTypeEnum.Sponsor] }
  },
  {
    path: '/campaigns/view/:id(\\d+)',
    name: 'campaignsView',
    component: () =>
      import(
        /* webpackChunkName: "campaignsView" */ '@/views/Campaigns/View.vue'
      ),
    meta: { onlyLoginTypes: [LoginTypeEnum.Sponsor] }
  },
  {
    path: '/campaigns/application/:id(\\d+)',
    name: 'sponsorApplicationsView',
    component: () =>
      import(
        /* webpackChunkName: "sponsorApplicationsView" */ '@/views/Sponsor/Applications/View.vue'
      ),
    meta: { onlyLoginTypes: [LoginTypeEnum.Sponsor] }
  },
  {
    path: '/my-sponsorship-deals',
    name: 'athleteMySponsorshipDealsIndex',
    component: () =>
      import(
        /* webpackChunkName: "athleteMySponsorshipDealsIndex" */ '@/views/Athlete/MySponsorshipDeals/Index.vue'
      ),
    meta: { onlyLoginTypes: [LoginTypeEnum.Athlete, LoginTypeEnum.AgentParent] }
  },
  {
    path: '/my-sponsorship-deals/view/:id(\\d+)',
    name: 'athleteMySponsorshipDealsView',
    component: () =>
      import(
        /* webpackChunkName: "athleteMySponsorshipDealsView" */ '@/views/Athlete/MySponsorshipDeals/View.vue'
      ),
    meta: { onlyLoginTypes: [LoginTypeEnum.Athlete, LoginTypeEnum.AgentParent] }
  },
  {
    path: '/sponsorship-deals',
    name: 'sponsorSponsorshipDealsIndex',
    component: () =>
      import(
        /* webpackChunkName: "sponsorSponsorshipDealsIndex" */ '@/views/Sponsor/SponsorshipDeals/Index.vue'
      ),
    meta: { onlyLoginTypes: [LoginTypeEnum.Sponsor] }
  },
  {
    path: '/sponsorship-deals/view/:id(\\d+)',
    name: 'sponsorSponsorshipDealsView',
    component: () =>
      import(
        /* webpackChunkName: "sponsorSponsorshipDealsView" */ '@/views/Sponsor/SponsorshipDeals/View.vue'
      ),
    meta: { onlyLoginTypes: [LoginTypeEnum.Sponsor] }
  },
  {
    path: '/browse-campaigns',
    name: 'browseCampaigns',
    component: () =>
      import(
        /* webpackChunkName: "browseCampaigns" */ '@/views/BrowseCampaigns.vue'
      ),
    meta: {
      allowIncompleteProfile: true,
      onlyLoginTypes: [LoginTypeEnum.Athlete, LoginTypeEnum.AgentParent]
    }
  },
  {
    path: '/search-athletes',
    name: 'searchAthletes',
    component: () =>
      import(
        /* webpackChunkName: "searchAthletes" */ '@/views/SearchAthletes.vue'
      ),
    meta: {
      allowIncompleteProfile: true,
      onlyLoginTypes: [
        LoginTypeEnum.Sponsor,
        LoginTypeEnum.Athlete,
        LoginTypeEnum.AgentParent,
        LoginTypeEnum.Fan
      ]
    }
  },
  {
    path: '/search-athletes/view/:id(.+)',
    name: 'searchAthleteView',
    component: () =>
      import(
        /* webpackChunkName: "searchAthleteView" */ '@/views/SearchAthleteView.vue'
      ),
    meta: {
      allowIncompleteProfile: true,
      onlyLoginTypes: [
        LoginTypeEnum.Sponsor,
        LoginTypeEnum.Athlete,
        LoginTypeEnum.AgentParent,
        LoginTypeEnum.Fan
      ]
    }
  },
  {
    path: '/messages',
    name: 'messages',
    component: () =>
      import(/* webpackChunkName: "messages" */ '@/views/Messages.vue'),
    meta: {
      onlyLoginTypes: [
        LoginTypeEnum.Athlete,
        LoginTypeEnum.AgentParent,
        LoginTypeEnum.Sponsor
      ]
    }
  },
  {
    path: '/profile/athlete',
    name: 'athleteProfile',
    component: () =>
      import(
        /* webpackChunkName: "athleteProfile" */ '@/views/Athlete/Profile/Index.vue'
      ),
    meta: {
      onlyLoginTypes: [LoginTypeEnum.Athlete, LoginTypeEnum.AgentParent]
    }
  },
  {
    path: '/profile/athlete/edit',
    name: 'athleteProfileEdit',
    component: () =>
      import(
        /* webpackChunkName: "athleteProfileEdit" */ '@/views/Athlete/Profile/Edit.vue'
      ),
    meta: {
      onlyLoginTypes: [LoginTypeEnum.Athlete, LoginTypeEnum.AgentParent]
    }
  },
  {
    path: '/profile/sponsor',
    name: 'sponsorProfile',
    component: () =>
      import(
        /* webpackChunkName: "sponsorProfile" */ '@/views/Sponsor/Profile.vue'
      ),
    meta: { onlyLoginTypes: [LoginTypeEnum.Sponsor] }
  },
  {
    path: '/profile/fan',
    name: 'fanProfile',
    component: () =>
      import(/* webpackChunkName: "fanProfile" */ '@/views/Fan/Profile.vue'),
    meta: { onlyLoginTypes: [LoginTypeEnum.Fan] }
  },
  {
    path: '/fan/social-feed',
    name: 'fanSocialFeed',
    component: () =>
      import(
        /* webpackChunkName: "fanSocialFeed" */ '@/views/Fan/Feeds/Index.vue'
      ),
    meta: { onlyLoginTypes: [LoginTypeEnum.Fan] }
  },
  {
    path: '/resources',
    name: 'resources',
    component: () =>
      import(/* webpackChunkName: "resources" */ '@/views/Resources.vue'),
    meta: {
      allowIncompleteProfile: true,
      onlyLoginTypes: [
        LoginTypeEnum.Athlete,
        LoginTypeEnum.AgentParent,
        LoginTypeEnum.Sponsor
      ]
    }
  },

  {
    path: '/backoffice',
    name: 'backofficeLogin',
    component: () =>
      import(
        /* webpackChunkName: "backofficeLogin" */ '@/views/Backoffice/Login.vue'
      ),
    meta: { allowAnonymous: true, hideMenu: true }
  },
  {
    path: '/backoffice/forgot-password',
    name: 'backofficeForgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "backofficeForgotPassword" */ '@/views/Backoffice/ForgotPassword.vue'
      ),
    meta: { allowAnonymous: true, hideMenu: true }
  },
  {
    path: '/backoffice/reset-password',
    name: 'backofficeResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "backofficeResetPassword" */ '@/views/Backoffice/ResetPassword.vue'
      ),
    meta: { allowAnonymous: true, hideMenu: true }
  },
  {
    path: '/backoffice/dashboard',
    name: 'backofficeDashboard',
    component: () =>
      import(
        /* webpackChunkName: "backofficeDashboard" */ '@/views/Backoffice/Dashboard/Index.vue'
      )
  },
  {
    path: '/backoffice/portal-users',
    name: 'backofficePortalUsersIndex',
    component: () =>
      import(
        /* webpackChunkName: "backofficePortalUsersIndex" */ '@/views/Backoffice/PortalUsers/Index.vue'
      )
  },
  {
    path: '/backoffice/portal-users/:userId([^/]+?)/:roleId?',
    name: 'backofficePortalUsersView',
    component: () =>
      import(
        /* webpackChunkName: "backofficePortalUsersView" */ '@/views/Backoffice/PortalUsers/View.vue'
      ),
    props: (route) => ({
      userId: route.params.userId,
      roleId: route.params.roleId
    })
  },
  {
    path: '/backoffice/users',
    name: 'backofficeUsersIndex',
    component: () =>
      import(
        /* webpackChunkName: "backofficeUsersIndex" */ '@/views/Backoffice/Users/Index.vue'
      )
  },
  {
    path: '/backoffice/users/new',
    name: 'backofficeUsersNew',
    component: () =>
      import(
        /* webpackChunkName: "backofficeUsersNew" */ '@/views/Backoffice/Users/View.vue'
      )
  },
  {
    path: '/backoffice/users/:id(.+)',
    name: 'backofficeUsersView',
    component: () =>
      import(
        /* webpackChunkName: "backofficeUsersView" */ '@/views/Backoffice/Users/View.vue'
      )
  },

  {
    path: '*',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: { allowAnonymous: true, hideMenu: true }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve((to, from, next) => {
  const authModule = getModule(AuthModule, store);
  const isAuthenticated = authModule.isAuthenticated;
  const hasCompletedProfile = authModule.hasCompletedProfile;

  if (executedBackofficeRules(isAuthenticated, authModule, to, next)) {
    return;
  }

  if (
    isAuthenticated &&
    to.name === 'dashboard' &&
    authModule.loginType === (LoginTypeEnum.Fan as number)
  ) {
    next({ name: 'fanSocialFeed' });
    return;
  }

  if (to.matched.some((record: any) => !!record.meta.allowAnonymous)) {
    if (isAuthenticated && to.name === 'Login') {
      next({ name: 'dashboard' });
    } else {
      // documentHelper.resetState();
      getModule(ServerErrorsModule, store).resetState();
      next();
    }
  } else {
    if (isAuthenticated) {
      if (
        !hasCompletedProfile &&
        to.matched.some((record: any) => !record.meta.allowIncompleteProfile)
      ) {
        next({ name: 'dashboard' });
      } else if (
        to.matched.length > 0 &&
        to.matched[0].meta.onlyLoginTypes !== undefined
      ) {
        const allowedList = to.matched[0].meta.onlyLoginTypes as Array<number>;
        if (allowedList.includes(authModule.loginType || 0)) {
          next();
        } else {
          next({ name: 'dashboard' });
        }
      } else {
        // documentHelper.resetState();
        getModule(ServerErrorsModule, store).resetState();
        next();
      }
    } else {
      next({ name: 'login' });
    }
  }
});

function executedBackofficeRules(
  isAuthenticated: boolean,
  authModule: AuthModule,
  to: Route,
  next: NavigationGuardNext<Vue>
) {
  if (to.name === '404') {
    next();
    return true;
  }

  const isBackofficeAuthenticated =
    isAuthenticated &&
    authModule.loginType === (LoginTypeEnum.Backoffice as number);

  const isGoingToBackofficeArea = to.path
    .toLowerCase()
    .startsWith('/backoffice');

  const routeDashboardName = 'backofficeDashboard';
  const routeLoginName = 'backofficeLogin';

  const toAllowAnonymous = to.matched.some(
    (record: any) => !!record.meta.allowAnonymous
  );

  if (!isGoingToBackofficeArea && isBackofficeAuthenticated) {
    next({ name: routeDashboardName });
    return true;
  }

  if (isGoingToBackofficeArea) {
    const goingToLogin = to.name === routeLoginName;

    if (isBackofficeAuthenticated && goingToLogin) {
      next({ name: routeDashboardName });
      return true;
    }

    if (!isBackofficeAuthenticated && !toAllowAnonymous) {
      next({ name: routeLoginName });
      return true;
    }

    next();
    return true;
  }

  return false;
}

export default router;
