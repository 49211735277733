import arm from '@/common/axiosVuexExtender/axiosRequestManager';
import { mocked_list_Success } from '@/models/campaign/listViewModel';
import CampaignApplicationDetailsViewModel, {
  mocked_get_Success
} from '@/models/campaign/applicationDetailsViewModel';
import CampaignNewViewModel, {
  CampaignSocialMediaDeliverable,
  CampaignAppearanceDeliverable,
  CampaignBaseDeliverable,
  mocked_getDraft_Success
} from '@/models/campaign/newViewModel';
import CustomFileUpload from '@/models/customFileUpload';

class SponsorCampaignService {
  private static instance: SponsorCampaignService;

  public static get Instance() {
    return this.instance || (this.instance = new this());
  }

  public async listAsync(
    initialLoad: boolean,
    page: number,
    pageSize?: number | null
  ) {
    const oRet = (
      await arm.get(
        '/sponsor/campaign/list',
        mocked_list_Success,
        initialLoad ? 'campaignList' : 'changingPage',
        undefined,
        {
          params: {
            currentPage: page,
            pageSize
          }
        }
      )
    ).data;

    if (oRet && oRet.data && oRet.data.length > 0) {
      oRet.data
        .filter((x) => x.coverImage)
        .forEach(
          (x) =>
            (x.coverImage = new CustomFileUpload(
              undefined,
              x.coverImage?.guid,
              x.coverImage?.fileName,
              x.coverImage?.fileSize,
              x.coverImage?.src
            ))
        );
    }

    return oRet;
  }

  public async getAsync(
    id: number
  ): Promise<CampaignApplicationDetailsViewModel | null> {
    return (
      await arm.get<CampaignApplicationDetailsViewModel>(
        `/sponsor/campaign/${id}`,
        mocked_get_Success
      )
    ).data;
  }

  public async deleteAsync(id: number): Promise<void> {
    return (
      await arm.delete(`/sponsor/campaign/${id}`, undefined, 'deletingCampaign')
    ).data;
  }

  public async getViewAsync(
    id: number
  ): Promise<CampaignApplicationDetailsViewModel | null> {
    const oRet = (
      await arm.get<CampaignApplicationDetailsViewModel | null>(
        `/sponsor/campaign/view/${id}`,
        mocked_get_Success
      )
    ).data;

    if (oRet && oRet.athletes && oRet.athletes.length > 0) {
      for (let i = 0; i < oRet.athletes.length; i++) {
        const athlete = oRet.athletes[i];
        if (athlete.profileImage) {
          athlete.profileImage = new CustomFileUpload(
            undefined,
            athlete.profileImage.guid,
            athlete.profileImage.fileName,
            athlete.profileImage.fileSize,
            athlete.profileImage.src
          );
        }
      }
    }

    return oRet;
  }

  public async getDraftAsync(
    id?: number | null
  ): Promise<CampaignNewViewModel | null> {
    if (!id) {
      return null;
    }

    const oRet = (
      await arm.get<CampaignNewViewModel>(
        `/sponsor/campaign/${id}`,
        Object.assign(mocked_getDraft_Success, { id }),
        'campaignLoad'
      )
    ).data;

    if (oRet.coverImage) {
      oRet.coverImage = new CustomFileUpload(
        undefined,
        oRet.coverImage.guid,
        oRet.coverImage.fileName,
        oRet.coverImage.fileSize,
        oRet.coverImage.src
      );
    }

    if (oRet.productImages && oRet.productImages.length > 0) {
      const newProdImages: Array<CustomFileUpload> = [];
      for (let i = 0; i < oRet.productImages.length; i++) {
        newProdImages.push(
          new CustomFileUpload(
            undefined,
            oRet.productImages[i].guid,
            oRet.productImages[i].fileName,
            oRet.productImages[i].fileSize,
            oRet.productImages[i].src
          )
        );
      }
      oRet.productImages = newProdImages;
    }

    if (
      oRet.socialMediaDeliverables &&
      oRet.socialMediaDeliverables.length > 0
    ) {
      for (let i = 0; i < oRet.socialMediaDeliverables.length; i++) {
        const socialMediaDeliverable = oRet.socialMediaDeliverables[i];

        if (socialMediaDeliverable.image) {
          socialMediaDeliverable.image = new CustomFileUpload(
            undefined,
            socialMediaDeliverable.image.guid,
            socialMediaDeliverable.image.fileName,
            socialMediaDeliverable.image.fileSize,
            socialMediaDeliverable.image.src
          );
        }
      }
    }

    return oRet;
  }

  public async getLoggedUserCompanyName() {
    return (
      await arm.get(
        '/sponsor/campaign/sponsorCompanyName',
        'Test123',
        'campaignLoad'
      )
    ).data;
  }

  public async insertUpdateDeliverableSocialMediaAsync(
    campaignId: number,
    payload: CampaignSocialMediaDeliverable
  ): Promise<number> {
    if (payload.id === undefined || payload.id === null) {
      return (
        await arm.post(
          '/sponsor/campaign/deliverableSocialMedia',
          Object.assign({ campaignId }, payload),
          1,
          'socialMediaDeliverable'
        )
      ).data;
    }

    await arm.put(
      `/sponsor/campaign/deliverableSocialMedia/${payload.id}`,
      Object.assign({ campaignId }, payload),
      undefined,
      'socialMediaDeliverable'
    );

    return payload.id;
  }

  public async removeDeliverableSocialMediaByIdAsync(
    id: number
  ): Promise<void> {
    await arm.delete(
      `/sponsor/campaign/deliverableSocialMedia/${id}`,
      undefined,
      'removeDeliverable'
    );
  }

  public async insertUpdateDeliverableAppearanceAsync(
    campaignId: number,
    payload: CampaignAppearanceDeliverable
  ): Promise<number> {
    if (payload.id === undefined || payload.id === null) {
      return (
        await arm.post(
          '/sponsor/campaign/deliverableAppearance',
          Object.assign({ campaignId }, payload),
          1,
          'appearanceDeliverable'
        )
      ).data;
    }

    await arm.put(
      `/sponsor/campaign/deliverableAppearance/${payload.id}`,
      Object.assign({ campaignId }, payload),
      undefined,
      'appearanceDeliverable'
    );

    return payload.id;
  }

  public async removeDeliverableAppearanceByIdAsync(id: number): Promise<void> {
    await arm.delete(
      `/sponsor/campaign/deliverableAppearance/${id}`,
      undefined,
      'removeDeliverable'
    );
  }

  public async insertUpdateDeliverableLogoPlacementAsync(
    campaignId: number,
    payload: CampaignBaseDeliverable
  ): Promise<number> {
    if (payload.id === undefined || payload.id === null) {
      return (
        await arm.post(
          '/sponsor/campaign/deliverableLogoPlacement',
          Object.assign({ campaignId }, payload),
          1,
          'logoPlacementDeliverable'
        )
      ).data;
    }

    await arm.put(
      `/sponsor/campaign/deliverableLogoPlacement/${payload.id}`,
      Object.assign({ campaignId }, payload),
      undefined,
      'logoPlacementDeliverable'
    );

    return payload.id;
  }

  public async removeDeliverableLogoPlacementByIdAsync(
    id: number
  ): Promise<void> {
    await arm.delete(
      `/sponsor/campaign/deliverableLogoPlacement/${id}`,
      undefined,
      'removeDeliverable'
    );
  }

  public async insertUpdateDeliverableProductFeedbackAsync(
    campaignId: number,
    payload: CampaignBaseDeliverable
  ): Promise<number> {
    if (payload.id === undefined || payload.id === null) {
      return (
        await arm.post(
          '/sponsor/campaign/deliverableProductFeedback',
          Object.assign({ campaignId }, payload),
          1,
          'productFeedbackDeliverable'
        )
      ).data;
    }

    await arm.put(
      `/sponsor/campaign/deliverableProductFeedback/${payload.id}`,
      Object.assign({ campaignId }, payload),
      undefined,
      'productFeedbackDeliverable'
    );

    return payload.id;
  }

  public async removeDeliverableProductFeedbackByIdAsync(
    id: number
  ): Promise<void> {
    await arm.delete(
      `/sponsor/campaign/deliverableProductFeedback/${id}`,
      undefined,
      'removeDeliverable'
    );
  }

  public async insertUpdateDeliverableOtherAsync(
    campaignId: number,
    payload: CampaignBaseDeliverable
  ): Promise<number> {
    if (payload.id === undefined || payload.id === null) {
      return (
        await arm.post(
          '/sponsor/campaign/deliverableOther',
          Object.assign({ campaignId }, payload),
          1,
          'otherDeliverable'
        )
      ).data;
    }

    await arm.put(
      `/sponsor/campaign/deliverableOther/${payload.id}`,
      Object.assign({ campaignId }, payload),
      undefined,
      'otherDeliverable'
    );

    return payload.id;
  }

  public async removeDeliverableOtherByIdAsync(id: number): Promise<void> {
    await arm.delete(
      `/sponsor/campaign/deliverableOther/${id}`,
      undefined,
      'removeDeliverable'
    );
  }

  public async insertAsync(
    payload: CampaignNewViewModel | null,
    groupName: string
  ) {
    return (
      await arm.post(
        '/sponsor/campaign',
        Object.assign(payload, {
          socialMediaBaseDeliverables: payload?.socialMediaDeliverables,
          appearanceBaseDeliverables: payload?.appearanceDeliverables,
          logoPlacementBaseDeliverables: payload?.logoPlacementDeliverables,
          productFeedbackBaseDeliverables: payload?.productFeedbackDeliverables,
          otherBaseDeliverables: payload?.otherDeliverables
        }),
        1,
        groupName
      )
    ).data;
  }

  public async updateAsync(
    id: number,
    payload: CampaignNewViewModel | null,
    groupName: string
  ) {
    await arm.put(
      `/sponsor/campaign/${id}`,
      Object.assign(payload, {
        socialMediaBaseDeliverables: payload?.socialMediaDeliverables,
        appearanceBaseDeliverables: payload?.appearanceDeliverables,
        logoPlacementBaseDeliverables: payload?.logoPlacementDeliverables,
        productFeedbackBaseDeliverables: payload?.productFeedbackDeliverables,
        otherBaseDeliverables: payload?.otherDeliverables
      }),
      undefined,
      groupName
    );
  }

  public async submitAsync(id: number) {
    return await arm.put(
      `/sponsor/campaign/submit/${id}`,
      undefined,
      1,
      'campaignSave'
    );
  }
}

export const sponsorCampaignService = SponsorCampaignService.Instance;
