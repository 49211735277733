import CustomFileUpload from '../customFileUpload';
import CampaignDetailsViewModel from './detailsViewModel';

export default interface CampaignNewViewModel {
  id?: number;

  // step 1
  name?: string | null;
  title?: string | null;
  description?: string | null;
  startDate?: Date | null;
  endDate?: Date | null;
  coverImage?: CustomFileUpload | null;
  productServiceDescription?: string | null;
  websiteUrl?: string | null;
  productImageUIds?: Array<string> | null;
  productImages?: Array<CustomFileUpload> | null;
  marketIds?: Array<number> | null;
  marketNames?: Array<string> | null;
  genderIds?: Array<number> | null;
  genderNames?: Array<string> | null;
  ageCategoryIds?: Array<number> | null;
  ageCategoryNames?: Array<string> | null;

  // step 2
  numberOfAthletes?: number | null;
  salesGoalIds?: Array<number> | null;
  salesGoalNames?: Array<string> | null;

  // step 3
  athleteTypeIds?: Array<number> | null;
  athleteTypeNames?: Array<string> | null;
  athleteSportIds?: Array<number> | null;
  athleteSportNames?: Array<string> | null;
  athleteLocations?: Array<string> | null;
  athleteGenderIds?: Array<number> | null;
  athleteGenderNames?: Array<string> | null;
  athleteSocialNetworkIds?: Array<number> | null;
  athleteSocialNetworkNames?: Array<string> | null;
  minimumFollowingRequired?: boolean | null;
  minimumFollowing?: number | null;
  customQuestions?: Array<string> | null;

  // step 4
  terms?: string;
  socialMediaDeliverables?: Array<CampaignSocialMediaDeliverable> | null;
  appearanceDeliverables?: Array<CampaignAppearanceDeliverable> | null;
  logoPlacementDeliverables?: Array<CampaignBaseDeliverable> | null;
  productFeedbackDeliverables?: Array<CampaignBaseDeliverable> | null;
  otherDeliverables?: Array<CampaignBaseDeliverable> | null;

  // step 5
  hasCashPayment?: boolean | null;
  hasProductPayment?: boolean | null;
  hasEquityPercentage?: boolean | null;
}

export interface CampaignSocialMediaDeliverable
  extends CampaignBaseDeliverable {
  postContent?: string | null;
  tags?: Array<string> | null;
  socialNetworkIds?: Array<number>;
  imageUId?: string | null;
  image?: CustomFileUpload | null;
}

export interface CampaignAppearanceDeliverable extends CampaignBaseDeliverable {
  locations?: Array<string> | null;
  appearanceTypeId?: number | null;
  appearanceTypeName?: string | null;
  travelExpenseIncluded?: boolean | null;
  accommodationExpenseIncluded?: boolean | null;
}

export interface CampaignBaseDeliverable {
  id?: number | null;
  title?: string | null;
  description?: string | null;
  duePeriodId?: number | null;
  duePeriodName?: string | null;
  dueDate?: Date | null;
  durationLength?: number | null;
  durationPeriodId?: number | null;
  durationPeriodName?: string | null;
  cashPayment?: number | null;
  productPayment?: number | null;
  equityPercentage?: number | null;
}

export const mocked_getDraft_Success: CampaignNewViewModel = {
  name: 'Loaded Name'
};

export function convertCampaignNewViewModelToCampaignDetailsViewModel(
  model: CampaignNewViewModel,
  sponsorCompanyName: string
): CampaignDetailsViewModel | null {
  const deliverableSums = getDeliverablesSum(model);

  return {
    id: model.id || 0,
    title: model.title,
    description: model.description,
    athleteTypeIds: model.athleteTypeIds,
    athleteTypeNames: model.athleteTypeNames,
    socialNetworkIds: model.athleteSocialNetworkIds,
    socialNetworkNames: model.athleteSocialNetworkNames,
    genderIds: model.genderIds,
    genderNames: model.genderNames,
    locations: model.athleteLocations,
    minimumFollowingRequired: model.minimumFollowingRequired,
    minimumFollowing: model.minimumFollowing,
    cashPayment: model.hasCashPayment ? deliverableSums.cashPayment : null,
    productPayment: model.hasProductPayment
      ? deliverableSums.productPayment
      : null,
    equityPercentage: model.hasEquityPercentage
      ? deliverableSums.equityPercentage
      : null,

    coverImage: model.coverImage,

    customQuestions: model.customQuestions,
    terms: model.terms,
    brandWebsite: model.websiteUrl,
    brandWebsiteWithProtocol: model.websiteUrl
      ? model.websiteUrl.toLowerCase().startsWith('http')
        ? model.websiteUrl
        : `https://${model.websiteUrl}`
      : model.websiteUrl,
    sponsorCompanyName,

    socialMediaDeliverables: model.socialMediaDeliverables,
    appearanceDeliverables: model.appearanceDeliverables,
    logoPlacementDeliverables: model.logoPlacementDeliverables,
    productFeedbackDeliverables: model.productFeedbackDeliverables,
    otherDeliverables: model.otherDeliverables
  };
}

function getDeliverablesSum(model?: CampaignNewViewModel | null): {
  cashPayment?: number | null;
  productPayment?: number | null;
  equityPercentage?: number | null;
} {
  if (!model) {
    return {};
  }

  const deliverables: Array<CampaignBaseDeliverable> = (
    model.socialMediaDeliverables || []
  )
    .concat(model.appearanceDeliverables || [])
    .concat(model.logoPlacementDeliverables || [])
    .concat(model.productFeedbackDeliverables || [])
    .concat(model.otherDeliverables || []);

  return {
    cashPayment: deliverables.reduce(
      (sum, item) => sum + (item.cashPayment || 0),
      0
    ),
    productPayment: deliverables.reduce(
      (sum, item) => sum + (item.productPayment || 0),
      0
    ),
    equityPercentage: deliverables.reduce(
      (sum, item) => sum + (item.equityPercentage || 0),
      0
    )
  };
}
