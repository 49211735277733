






























































import BaseVue from '@/common/baseVue';
import { Component } from 'vue-property-decorator';
import DevModeOnlyWrapper from '@/components/DevModeOnlyWrapper.vue';
import CustomButton from '@/components/Controls/CustomButton.vue';
import TextBox from '@/components/Controls/TextBox.vue';

@Component({
  components: {
    DevModeOnlyWrapper,
    CustomButton,
    TextBox
  }
})
export default class Login extends BaseVue {
  email = '';
  password = '';
  errMsg?: string | null = null;

  mounted() {
    if (this.authModule.isAuthenticated) {
      this.routerHelper.goToDashboard();
    }
  }

  get isLoading() {
    return this.requestManagerModule.hasRequest('loginRequest');
  }

  async ppAthlete() {
    this.email = 'athlete@anewicon.com';
    this.password = 'Athlete*1';
    await this.loginSend();
  }

  async ppIathlete() {
    this.email = 'iathlete@anewicon.com';
    this.password = 'Athlete*1';
    await this.loginSend();
  }

  async ppSponsor() {
    this.email = 'sponsor@anewicon.com';
    this.password = 'Sponsor*1';
    await this.loginSend();
  }

  async ppIsponsor() {
    this.email = 'isponsor@anewicon.com';
    this.password = 'Sponsor*1';
    await this.loginSend();
  }

  async ppParent() {
    this.email = 'parent@anewicon.com';
    this.password = 'Parent*1';
    await this.loginSend();
  }

  async ppIparent() {
    this.email = 'iparent@anewicon.com';
    this.password = 'Parent*1';
    await this.loginSend();
  }

  async ppFan() {
    this.email = 'fan@anewicon.com';
    this.password = 'FanFan*1';
    await this.loginSend();
  }

  async loginSend() {
    try {
      const result = await this.authModule.login({
        email: this.email,
        password: this.password
      });

      if (result.success) {
        this.routerHelper.goToDashboard();
      } else {
        this.errMsg = result.errorMessage;
      }
    } catch (err) {
      await this.errorHandlingAsync(err);
    }
  }

  signup() {
    this.routerHelper.pushToRoute('signUp');
  }
}
