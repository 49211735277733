








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CustomButton extends Vue {
  @Prop({ default: 'secondary' }) private variant?: string;
}
