import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import AuthModule from './modules/auth';
import RequestManagerModule from '@/common/axiosVuexExtender/storeModule/requestManager';
import CampaignModule from './modules/campaign';
import ProfileModule from './modules/profile';
import ServerErrorsModule from './modules/serverErrors';

const vuexLocal = new VuexPersistence<any>({
  key: 'my-app',
  storage: window.localStorage,
  reducer: (state) => {
    const localState = Object.assign({}, state);
    for (const key in localState) {
      if (
        key === 'RequestManagerModule' ||
        key === 'CampaignModule' ||
        key === 'ProfileModule' ||
        key === 'ServerErrorsModule'
      ) {
        delete localState[key];
      }
    }
    return localState;
  }
});

const vuexSession = new VuexPersistence<any>({
  key: 'my-app',
  storage: window.sessionStorage,
  reducer: (state) => {
    const sessionState = Object.assign({}, state);
    for (const key in sessionState) {
      if (key !== 'CampaignModule' && key !== 'ProfileModule') {
        delete sessionState[key];
      }
    }
    return sessionState;
  }
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    AuthModule,
    RequestManagerModule,
    CampaignModule,
    ProfileModule,
    ServerErrorsModule
  },
  plugins: [vuexLocal.plugin, vuexSession.plugin]
});
