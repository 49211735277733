let apiBaseURL: string = process.env.VUE_APP_APIBASEURL || '';
if (apiBaseURL.startsWith('#{')) apiBaseURL = '';

let dropdownItemsJsPath: string = process.env.VUE_APP_DROPDOWNITEMSJSPATH || '';
if (dropdownItemsJsPath.startsWith('#{')) dropdownItemsJsPath = '';

let useMockedResponse: string | boolean =
  process.env.VUE_APP_USEMOCKEDRESPONSE || 'false';
useMockedResponse = useMockedResponse === 'true' || useMockedResponse === '1';

let isDevMode: string | boolean = process.env.VUE_APP_ISDEV || 'false';
isDevMode = isDevMode === 'true' || isDevMode === '1';

let devForceDebug: string | boolean =
  process.env.VUE_APP_DEV_FORCEDEBUG || 'false';
devForceDebug = devForceDebug === 'true' || devForceDebug === '1';

let devForcePp: string | boolean = process.env.VUE_APP_DEV_FORCEPP || 'false';
devForcePp = devForcePp === 'true' || devForcePp === '1';

let paypalSandbox: string | boolean =
  process.env.VUE_APP_PAYPAL_USESANDBOX || 'false';
paypalSandbox = paypalSandbox === 'true' || paypalSandbox === '1';

let paypalAppId: string = process.env.VUE_APP_PAYPAL_APPID || '';
if (paypalAppId.startsWith('#{')) paypalAppId = '';

export {
  apiBaseURL,
  dropdownItemsJsPath,
  useMockedResponse,
  isDevMode,
  devForceDebug,
  devForcePp,
  paypalSandbox,
  paypalAppId
};
