import arm from '@/common/axiosVuexExtender/axiosRequestManager';
import { mocked_athleteDetailsViewModel_getByIdAsync } from '@/models/athlete/detailsViewModel';
import AthleteProfileViewModel from '@/models/athlete/profileViewModel';
import { mocked_athleteProfileWithHeroViewModel_getProfile } from '@/models/athlete/profileWithHeroViewModel';
import CustomFileUpload from '@/models/customFileUpload';

class AthleteProfileService {
  private static instance: AthleteProfileService;

  public static get Instance() {
    return this.instance || (this.instance = new this());
  }

  public async getProfileAsync(addGroupName: boolean) {
    const oRet = (
      await arm.get(
        '/athlete/profile',
        mocked_athleteProfileWithHeroViewModel_getProfile,
        addGroupName ? 'profileGet' : undefined
      )
    ).data;

    if (oRet.heroImage) {
      oRet.heroImage = new CustomFileUpload(
        undefined,
        oRet.heroImage.guid,
        oRet.heroImage.fileName,
        oRet.heroImage.fileSize,
        oRet.heroImage.src
      );
    }

    return oRet;
  }

  public async saveProfileAsync(data: AthleteProfileViewModel | null) {
    await arm.put<any>('/athlete/profile', data, undefined, 'profilePut');
  }

  public async submitAsync(profile: AthleteProfileViewModel | null) {
    return await arm.put<any>(
      '/athlete/profile',
      { ...profile, submit: true },
      {},
      'profileSubmit'
    );
  }

  public async setHeroImageAsync(newImage?: CustomFileUpload | null) {
    const response = (
      await arm.post(
        '/athlete/profile/setHeroImage',
        newImage || null,
        newImage,
        'saveHeroImage'
      )
    ).data;

    if (!response) {
      return null;
    }

    return new CustomFileUpload(
      undefined,
      response.guid,
      response.fileName,
      response.fileSize,
      response.src
    );
  }

  public async setProfileImageAsync(newImage: CustomFileUpload) {
    return (
      await arm.post(
        '/athlete/profile/setProfileImage',
        newImage,
        newImage,
        'saveProfileImage'
      )
    ).data;
  }

  public async setThemeAsync(theme: string) {
    return (
      await arm.post(
        '/athlete/profile/setTheme',
        { theme },
        undefined,
        'setTheme'
      )
    ).data;
  }

  public async getProfileViewAsync() {
    const oRet = (
      await arm.get(
        '/athlete/profile/profileView',
        mocked_athleteDetailsViewModel_getByIdAsync,
        'retrievingAthlete'
      )
    ).data;

    if (oRet.image) {
      oRet.image = new CustomFileUpload(
        undefined,
        oRet.image.guid,
        oRet.image.fileName,
        oRet.image.fileSize,
        oRet.image.src
      );
    }

    return oRet;
  }
}
export const athleteProfileService = AthleteProfileService.Instance;
