import arm from '@/common/axiosVuexExtender/axiosRequestManager';
import CustomFileUpload from '@/models/customFileUpload';
import LoginResponse, {
  mocked_loginBackoffice_Success
} from '@/models/loginResponse';

class BackofficeAuthService {
  private static instance: BackofficeAuthService;

  public static get Instance() {
    return this.instance || (this.instance = new this());
  }

  public async loginAsync(email: string, password: string) {
    const response = await arm.post<LoginResponse>(
      '/backoffice/login',
      { email, password },
      mocked_loginBackoffice_Success,
      'loginRequest'
    );

    const oRet = response.data;
    if (oRet.profileImage) {
      oRet.profileImage = new CustomFileUpload(
        undefined,
        oRet.profileImage.guid,
        oRet.profileImage.fileName,
        oRet.profileImage.fileSize,
        oRet.profileImage.src
      );
    }

    return oRet;
  }

  public async logoutAsync() {
    await arm.post('/backoffice/logout', null, null);
  }
}

export const backofficeAuthService = BackofficeAuthService.Instance;
