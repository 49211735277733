import { Canceler } from 'axios';

export default class CustomFileUpload {
  file?: File;
  guid: string | null = null;
  fileName: string;
  fileSize?: string;
  src: string;
  completed = false;
  uploadProgress = 0;
  cancelFunction?: Canceler;
  showImageAndRemoveButton = false;

  constructor(
    file?: File,
    guid?: string | null,
    fileName?: string,
    fileSize?: string,
    src?: string
  ) {
    this.file = file;
    this.guid = guid || null;
    this.fileName = file?.name || fileName || '';
    this.fileSize = file ? (file.size / 1048576).toFixed(2) + 'Mb' : fileSize;
    this.src = file ? URL.createObjectURL(file) : src || '';

    if (!file) {
      this.completed = true;
      this.uploadProgress = 100;
      this.showImageAndRemoveButton = true;
    }
  }
}

export enum CustomFileUploadTypeEnum {
  AthleteProfile = 1,
  SponsorProfile = 2,
  CampaignCover = 3,
  CampaignProduct = 4,
  CampaignSocialDeliverable = 5,
  AthletePostImage = 6,
  Contract = 7,
  FanProfile = 8,
  AthleteHeroImage = 9,
  BackofficeProfile = 10
}
