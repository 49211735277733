import moment from 'moment';
import CustomFileUpload from '../customFileUpload';
import PagedList from '../pagedList';

export default interface CampaignListViewModel {
  id: number;
  name: string;
  coverImage?: CustomFileUpload | null;
  createdDate: Date | null;
  responseCount: number;
  finished: boolean;
}

export const mocked_list_Success: PagedList<CampaignListViewModel> = {
  totalItemsCount: 3,
  totalPages: 1,
  pageSize: 20,
  currentPage: 1,
  pages: [1],
  nextEnabled: false,
  prevEnabled: false,
  data: [
    {
      id: 1,
      coverImage: new CustomFileUpload(
        undefined,
        '',
        '',
        '',
        'https://media.istockphoto.com/photos/silhouette-action-sport-picture-id1272269793?k=20&m=1272269793&s=612x612&w=0&h=OXGJDqSgZEgZgcJjtBuYjO_DoT6r6LRRx5wyPaUR9qs='
      ),
      name: 'Name 1',
      createdDate: moment('2022-01-04').toDate(),
      responseCount: 25,
      finished: true
    },
    {
      id: 2,
      coverImage: new CustomFileUpload(
        undefined,
        '',
        '',
        '',
        'https://img.freepik.com/free-vector/soccer-volleyball-baseball-rugby-equipment_1441-4026.jpg?size=626&ext=jpg'
      ),
      name: 'Name 2',
      createdDate: moment('2022-01-16').toDate(),
      responseCount: 4,
      finished: true
    },
    {
      id: 3,
      coverImage: null,
      name: 'Draft 3',
      createdDate: null,
      responseCount: 0,
      finished: false
    }
  ]
};
