import CustomFileUpload from '@/models/customFileUpload';
import { mocked_postViewModel_list, PostViewModel } from '@/models/postModels';
import AthleteDetailsInvitableCampaignViewModel from './detailsInvitableCampaignViewModel';

export default interface AthleteDetailsViewModel {
  image: CustomFileUpload;
  heroImage?: CustomFileUpload | null;
  firstName: string;
  surname: string;
  ratingScore?: number | null;
  sportNames: string[];
  location: string;
  pronoun?: string | null;
  shortDescription?: string | null;
  relevantAchievements?: string | null;
  age: number;
  genderNames: string[];
  athleteTypeName: string;
  sportTypeNames: string[];
  nationality: string;
  lifestyleNames: string[];
  instagram: string | null;
  instagramFollowers: string | null;
  twitter: string | null;
  twitterFollowers: string | null;
  tiktok: string | null;
  tiktokFollowers: string | null;
  youtube: string | null;
  youtubeFollowers: string | null;
  website: string | null;
  websiteWithProtocol: string | null;
  viewingOneSelf: boolean;
  isFavourite: boolean;
  invitableCampaigns?: Array<AthleteDetailsInvitableCampaignViewModel> | null;
  posts?: Array<PostViewModel> | null;
  frontEndUrl: string;
  athleteId: string;
  linkedinSharingLink: string;
  twitterSharingLink: string;
  facebookSharingLink: string;
  hasAgent: boolean;
  agentParentFirstName: string;
  agentParentSurname: string;
}

export const mocked_athleteDetailsViewModel_getByIdAsync: AthleteDetailsViewModel =
  {
    image: new CustomFileUpload(
      undefined,
      '06137a46-1180-4e1e-8d83-071b9659ab87',
      '_profileTest2.png',
      '0.01Mb',
      'https://media.glamour.com/photos/5a425fd3b6bcee68da9f86f8/1:1/w_120,c_limit/best-face-oil.png'
    ),
    // heroImage: new CustomFileUpload(
    //   undefined,
    //   '06137a46-1180-4e1e-8d83-071b9659ab87',
    //   '_profileTest2.png',
    //   '0.01Mb',
    //   'https://images.unsplash.com/photo-1522542194-2c2e6ffcf7d8?auto&#x3D;format&amp;fit&#x3D;crop&amp;w&#x3D;356&amp;h&#x3D;156&amp;q&#x3D;80'
    // ),
    firstName: 'Jay',
    surname: 'Smith',
    sportNames: ['Archery', 'Baseball'],
    location: 'United Kingdom',
    pronoun: 'he/him',
    shortDescription: 'Field 201',
    relevantAchievements: 'Field 202',
    age: 22,
    genderNames: [],
    athleteTypeName: 'Semi-Professional',
    sportTypeNames: ['Traditional', 'E-sport'],
    nationality: 'British',
    lifestyleNames: ['Nutrition', 'Vegan'],
    instagram: 'MagiClick',
    instagramFollowers: '302',
    twitter: 'MagiClick',
    twitterFollowers: '304',
    tiktok: 'MagiClick',
    tiktokFollowers: '306',
    youtube: 'MagiClick',
    youtubeFollowers: '308',
    website: 'bbc.co.uk',
    websiteWithProtocol: 'https://bbc.co.uk',
    viewingOneSelf: false,
    isFavourite: false,
    invitableCampaigns: null,
    posts: mocked_postViewModel_list,
    frontEndUrl: 'test',
    athleteId: 'abc',
    linkedinSharingLink: 'string',
    twitterSharingLink: 'string',
    facebookSharingLink: 'string',
    hasAgent: true,
    agentParentFirstName: 'Agent First Name',
    agentParentSurname: 'Agent Surname'
  };
