






















































import BaseVue from '@/common/baseVue';
import { Component } from 'vue-property-decorator';

@Component
export default class NavBarMobile extends BaseVue {
  get imgSrc() {
    return this.authModule.profileImage?.src;
  }

  goToDashboard() {
    if (this.isBackoffice) {
      this.routerHelper.goToBackofficeDashboard();
    } else {
      this.routerHelper.goToDashboard();
    }
  }
}
