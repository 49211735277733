import { VueConstructor } from 'vue/types/umd';
import {
  localeCode,
  getValidDateOrNull,
  getValidNumberOrNanOrNull,
  escapeHtmlAndAddLinebreak
} from '@/helpers/functionsHelper';
import { Moment, isMoment } from 'moment';

export const formatter = new Intl.NumberFormat(localeCode, {
  style: 'currency',
  currency: 'GBP'
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const formatterNoDecimal = new Intl.NumberFormat(localeCode, {
  style: 'currency',
  currency: 'GBP',
  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0 // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
});

export default {
  install(Vue: VueConstructor) {
    Vue.filter('yesno', (value: boolean) => {
      return value ? 'Yes' : 'No';
    });

    Vue.filter('truefalse', (value: boolean) => {
      return value ? 'True' : 'False';
    });

    Vue.filter('dateLocale', (value: Date | Moment | string) => {
      const format = 'L';
      if (isMoment(value)) {
        return value.format(format);
      } else {
        const dtValue = getValidDateOrNull(value);
        if (dtValue === null) {
          return '';
        }

        return dtValue.format(format);
      }
    });

    Vue.filter('dateDashTimeLocale', (value: Date | Moment | string) => {
      const format = 'DD/MM/YYYY - HH:mm';
      if (isMoment(value)) {
        return value.format(format);
      } else {
        const dtValue = getValidDateOrNull(value);
        if (dtValue === null) {
          return '';
        }

        return dtValue.format(format);
      }
    });

    Vue.filter('dateLocaleMonth', (value: Date | Moment | string) => {
      const format = 'DD MMMM, YYYY';
      if (isMoment(value)) {
        return value.format(format);
      } else {
        const dtValue = getValidDateOrNull(value);
        if (dtValue === null) {
          return '';
        }

        return dtValue.format(format);
      }
    });

    Vue.filter('dateLocaleMonthTime', (value: Date | Moment | string) => {
      const format = 'DD MMMM YYYY HH:mm:ss';
      if (isMoment(value)) {
        return value.format(format);
      } else {
        const dtValue = getValidDateOrNull(value);
        if (dtValue === null) {
          return '';
        }

        return dtValue.format(format);
      }
    });

    Vue.filter('datePost', (value: Date | Moment | string) => {
      const format = 'D MMMM YYYY [at] HH:mm';
      if (isMoment(value)) {
        return value.format(format);
      } else {
        const dtValue = getValidDateOrNull(value);
        if (dtValue === null) {
          return '';
        }

        return dtValue.format(format);
      }
    });

    Vue.filter('dateLocaleChat', (value: Date | Moment | string) => {
      const format = 'HH:MM | DD MMMM';
      if (isMoment(value)) {
        return value.format(format);
      } else {
        const dtValue = getValidDateOrNull(value);
        if (dtValue === null) {
          return '';
        }

        return dtValue.format(format);
      }
    });

    Vue.filter('currency', (value: string | number | null) => {
      const numValue = getValidNumberOrNanOrNull(value);
      if (Number.isNaN(numValue)) {
        return 'NaN';
      }

      if (numValue === null) {
        return '';
      }

      return formatter.format(numValue);
    });

    Vue.filter('currencyDynamic', (value: string | number | null) => {
      const numValue = getValidNumberOrNanOrNull(value);
      if (Number.isNaN(numValue)) {
        return 'NaN';
      }

      if (numValue === null) {
        return '';
      }

      const showDecimals = value !== Math.floor(numValue);
      if (showDecimals) {
        return formatter.format(numValue);
      }
      return formatterNoDecimal.format(numValue);
    });

    Vue.filter('currencyNoDecimal', (value: string | number | null) => {
      const numValue = getValidNumberOrNanOrNull(value);
      if (Number.isNaN(numValue)) {
        return 'NaN';
      }

      if (numValue === null) {
        return '';
      }

      return formatterNoDecimal.format(numValue);
    });

    Vue.filter('escapeHtmlAndAddLinebreak', (value?: any | null) => {
      if (value === null || value === undefined) {
        return '';
      }

      if (value.toString) {
        return escapeHtmlAndAddLinebreak(value.toString().trim());
      }

      return value;
    });

    Vue.filter(
      'forceDecimals',
      (value: number | null | undefined, decimalCases: number | null) => {
        if (value === null || value === undefined) {
          return '';
        }

        if (decimalCases === null) {
          return value.toString();
        }

        return value.toFixed(decimalCases);
      }
    );
  }
};
