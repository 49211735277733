



























import BaseVue from '@/common/baseVue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class DevModeOnlyWrapper extends BaseVue {
  @Prop({ default: true }) private showOnlyOnDebugMode?: boolean;
  @Prop({ default: false }) private showOnlyOnPpMode?: boolean;
  @Prop() private innerClass?: string;
  visible = true;

  get showElement() {
    if (process.env.NODE_ENV === 'production') {
      return false;
    }

    if (!this.isDevMode) {
      return false;
    }

    if (!this.isDevDebugMode && this.showOnlyOnDebugMode) {
      return false;
    }

    if (!this.isDevPpMode && this.showOnlyOnPpMode) {
      return false;
    }

    return this.isDevMode;
  }
}
