import CustomFileUpload from '../customFileUpload';
import AthleteProfileViewModel, {
  mocked_athleteProfileViewModel_getProfile
} from './profileViewModel';

export default interface AthleteProfileWithHeroViewModel
  extends AthleteProfileViewModel {
  heroImage: CustomFileUpload | null;
}

export const mocked_athleteProfileWithHeroViewModel_getProfile: AthleteProfileWithHeroViewModel =
  {
    heroImage: null,
    ...mocked_athleteProfileViewModel_getProfile
  };
