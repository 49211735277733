import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'ServerErrorsModule' })
export default class ServerErrorsModule extends VuexModule {
  serverSideErrors: Array<string> = [];

  get hasAnyServerSideError() {
    return this.serverSideErrors.length > 0;
  }

  get serverSideErrorList() {
    return this.serverSideErrors;
  }

  @Mutation
  resetState() {
    this.serverSideErrors = [];
  }

  @Mutation
  addErrors(error: string | string[]) {
    if (typeof error === 'string') {
      this.serverSideErrors.push(error);
    } else if (error && error.length) {
      for (let i = 0; i < error.length; i++) {
        this.serverSideErrors.push(error[i]);
      }
    }
  }
}
