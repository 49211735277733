import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'RequestManagerModule' })
export default class RequestManagerModule extends VuexModule {
  forceShowLoading = false;
  globalReqCount = 0;
  requests: string[] = [];
  // initialLoadingIgnoreInLoader = false
  // initialLoadingCompleted = true

  get hasRequest() {
    return (requestName: string | Array<string>) => {
      if (Array.isArray(requestName)) {
        for (let i = this.requests.length - 1; i >= 0; i--) {
          if (requestName.includes(this.requests[i])) {
            return true;
          }
        }
      } else {
        for (let i = this.requests.length - 1; i >= 0; i--) {
          if (this.requests[i] === requestName) {
            return true;
          }
        }
      }
      return false;
    };
  }

  get isLoading() {
    return (includeIgnored = false) => {
      return (
        this.globalReqCount > 0 ||
        this.forceShowLoading ||
        (includeIgnored && this.requests.length > 0)
      );
    };
  }

  @Mutation
  resetState() {
    this.forceShowLoading = false;
    this.globalReqCount = 0;
    this.requests = [];
  }

  @Mutation
  addRequest(groupName: string | null | undefined) {
    if (groupName) {
      this.requests.push(groupName);
    } else {
      this.globalReqCount++;
    }
  }

  @Mutation
  removeRequest(groupName: string | null | undefined) {
    if (groupName) {
      for (let i = this.requests.length - 1; i >= 0; i--) {
        if (this.requests[i] === groupName) {
          this.requests.splice(i, 1);
          break;
        }
      }
    } else {
      this.globalReqCount = Math.max(0, this.globalReqCount - 1);
    }
  }

  @Mutation
  showLoading() {
    this.forceShowLoading = true;
  }

  @Mutation
  hideLoading() {
    this.forceShowLoading = false;
  }
}
