import moment from 'moment';

export default interface AthleteProfileViewModel {
  id?: string | null;
  firstName?: string | null;
  surname?: string | null;
  agentParentFirstName?: string | null;
  agentParentSurname?: string | null;
  ratingScore?: number | null;
  dateOfBirth?: Date | null;
  nationality?: Array<string> | null;
  countryOfResidence?: string | null;
  athleteTypeId?: number | null;
  athleteTypeName?: string | null;
  sportTypes?: Array<number> | null;
  sportTypeNames?: Array<string> | null;
  sports?: Array<number> | null;
  sportNames?: Array<string> | null;
  anyCurrentlySponsor?: boolean | null;
  athleteProfileSponsorContracts?: Array<{
    name: string;
    contractEndDate: Date;
  }> | null;

  shortDescription?: string | null;
  wasInCompetitionOrWonAward?: boolean | null;
  relevantAchievements?: string;
  lifestyles?: Array<number> | null;
  lifestyleNames?: Array<string> | null;
  genders?: Array<number> | null;
  genderNames?: Array<string> | null;
  genderIgnore?: boolean;
  sexualityId?: number | null;
  sexualityName?: string | null;
  sexualityIgnore?: boolean;
  pronouns?: Array<number> | null;
  pronounNames?: Array<string> | null;
  pronounIgnore?: boolean;

  instagram?: string | null;
  instagramFollowers?: number | null;
  twitter?: string | null;
  twitterFollowers?: number | null;
  tiktok?: string | null;
  tiktokFollowers?: number | null;
  youtube?: string | null;
  youtubeFollowers?: number | null;
  website?: string | null;

  socialMediaPriceStartingFrom?: number | null;
  brandAmbassadorPriceStartingFrom?: number | null;
  appearancesPriceStartingFrom?: number | null;
  hasPayPalConnection?: boolean | null;
}

export const mocked_athleteProfileViewModel_getProfile: AthleteProfileViewModel =
  {
    firstName: 'Tom',
    surname: 'Jones',
    dateOfBirth: moment('1999-01-11').toDate(),
    nationality: ['British'],
    countryOfResidence: 'United Kingdom',
    athleteTypeId: 2,
    athleteTypeName: 'Athlete Type 2',
    sportTypes: [3],
    sportTypeNames: ['Sport Type 3'],
    sports: [1],
    sportNames: ['Sport 1'],
    anyCurrentlySponsor: true,
    athleteProfileSponsorContracts: [
      {
        name: 'Sponsor X',
        contractEndDate: moment('2023-01-01T00:00:00').toDate()
      },
      {
        name: 'Sponsor Y',
        contractEndDate: moment('2023-02-13T00:00:00').toDate()
      }
    ],

    shortDescription: 'some text here',
    wasInCompetitionOrWonAward: true,
    relevantAchievements: 'Won x, y and z',
    lifestyles: [3],
    lifestyleNames: ['Lifestyle 3'],
    genders: [1],
    genderNames: ['Gender 1'],
    genderIgnore: false,
    sexualityId: 2,
    sexualityName: 'Sexuality 2',
    sexualityIgnore: false,
    pronouns: [1],
    pronounNames: ['Pronoun 1'],
    pronounIgnore: false,

    instagram: 'test1',
    instagramFollowers: 10,
    twitter: 'test2',
    twitterFollowers: 20,
    tiktok: 'test3',
    tiktokFollowers: 30,
    youtube: 'test4',
    youtubeFollowers: 40,
    website: 'test5',

    socialMediaPriceStartingFrom: 87,
    brandAmbassadorPriceStartingFrom: 18.55,
    appearancesPriceStartingFrom: 350.15
  };
