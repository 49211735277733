import moment from 'moment';

export default interface CampaignApplicationDetailsAthleteActivityViewModel {
  description: string;
  createDate: Date;
}

export const mocked_campaignApplicationDetailsAthleteActivityViewModel_get: Array<CampaignApplicationDetailsAthleteActivityViewModel> =
  [
    {
      description: 'Lewis Hamilton expressed interest',
      createDate: moment('2022-02-23T15:56:28.2858916').toDate()
    },
    {
      description: 'Submitted invitation to Lewis Hamilton',
      createDate: moment('2022-02-24T21:53:28.2858931').toDate()
    },
    {
      description: 'Lewis Hamilton is requesting a proposal',
      createDate: moment('2022-02-25T17:50:28.2858934').toDate()
    }
  ];
