import arm from '@/common/axiosVuexExtender/axiosRequestManager';
import CustomFileUpload from '@/models/customFileUpload';
import SponsorProfileViewModel, {
  mocked_sponsorProfileViewModel_getProfile
} from '@/models/sponsor/profileViewModel';

class SponsorProfileService {
  private static instance: SponsorProfileService;

  public static get Instance() {
    return this.instance || (this.instance = new this());
  }

  public async getProfileAsync(addGroupName: boolean) {
    const oRet = (
      await arm.get<SponsorProfileViewModel>(
        '/sponsor/profile',
        mocked_sponsorProfileViewModel_getProfile,
        addGroupName ? 'profileGet' : undefined
      )
    ).data;

    return oRet;
  }

  public async saveProfileAsync(data: SponsorProfileViewModel | null) {
    await arm.put<any>('/sponsor/profile', data, undefined, 'profilePut');
  }

  public async submitAsync(profile: SponsorProfileViewModel | null) {
    return await arm.put<any>(
      '/sponsor/profile',
      { submit: true, ...profile },
      {},
      'profileSubmit'
    );
  }

  public async setProfileImageAsync(newImage: CustomFileUpload) {
    return (
      await arm.post(
        '/sponsor/profile/setProfileImage',
        newImage,
        newImage,
        'saveProfileImage'
      )
    ).data;
  }

  public async setThemeAsync(theme: string) {
    return (
      await arm.post(
        '/sponsor/profile/setTheme',
        { theme },
        undefined,
        'setTheme'
      )
    ).data;
  }
}
export const sponsorProfileService = SponsorProfileService.Instance;
