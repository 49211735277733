import moment from 'moment';
import CustomFileUpload from './customFileUpload';

export interface NewPostRequest {
  content: string;
  imageUID?: string | null;
  image?: CustomFileUpload | null;
}

export interface UpdatePostViewModel extends NewPostRequest {
  id: number;
}

export interface PostViewModel extends UpdatePostViewModel {
  authorId: string;
  authorType: string;
  createDate: Date;
  fullName: string;
  profileImage: CustomFileUpload;
  comments?: Array<PostCommentViewModel> | null;
  originalPost?: PostViewModel | null;
}

export interface PostCommentReplyViewModel {
  id: number;
  content: string;
  fullName: string;
  profileImage: CustomFileUpload;
  dateCreated: Date;
}

export interface PostCommentViewModel extends PostCommentReplyViewModel {
  replies?: Array<PostCommentReplyViewModel> | null;
}
const faceImg =
  'https://images.unsplash.com/photo-1493106819501-66d381c466f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=100&h=100&q=80';
const faceImg2 =
  'https://images.unsplash.com/photo-1553514029-1318c9127859?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=100&h=100&q=80';
const faceImg3 =
  'https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=100&h=100&q=80';
// const faceImg4 =
//   'https://images.unsplash.com/photo-1602452920335-6a132309c7c8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=100&h=100&q=80';
// const faceImg5 =
//   'https://images.unsplash.com/photo-1587064712555-6e206484699b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=100&h=100&q=80';
const uploadedImg =
  'https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=450&q=80';
const uploadedImg2 =
  'https://images.unsplash.com/photo-1517048676732-d65bc937f952?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=450&q=80';
export const mocked_postViewModel_list: Array<PostViewModel> = [
  {
    authorId: 'test',
    authorType: 'Athlete',
    createDate: moment('2022-05-04T21:59:55').toDate(),
    originalPost: {
      authorId: 'abc',
      authorType: 'Athlete',
      createDate: moment('2022-05-04T20:16:08').toDate(),
      originalPost: null,
      comments: [],
      fullName: 'Jay Smith',
      profileImage: new CustomFileUpload(
        undefined,
        '06137a46-1180-4e1e-8d83-071b9659ab87',
        '_profileTest2.png',
        '0.01Mb',
        faceImg2
      ),
      id: 2,
      content: 'asdfasf',
      image: new CustomFileUpload(
        undefined,
        '5b55b031-1cf5-47d0-ba31-3ded63536252',
        '_example1.jpg',
        '0.12Mb',
        uploadedImg2
      )
    },
    comments: [
      {
        id: 18,

        content:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget vehicula turpis. Proin et leo quis ligula finibus congue. Nulla sagittis pharetra porta. Cras eu maximus est. Integer faucibus eros augue, nec bibendum diam venenatis vel. Donec sed neque id est vulputate luctus mollis vitae sapien. Maecenas non felis condimentum, lobortis libero quis, congue turpis. Nullam hendrerit ante augue, ut lobortis lacus aliquet et.',
        fullName: 'Mary Smith',
        profileImage: new CustomFileUpload(
          undefined,
          'd15c5fb5-4d77-4742-9ffd-f391c109c197',
          '_profileTest.png',
          '0.03Mb',
          faceImg
        ),
        dateCreated: moment('2022-05-09T12:55:44').toDate(),
        replies: [
          {
            id: 20,

            content:
              'Fusce semper at orci vitae condimentum. Maecenas pulvinar convallis sem non molestie. Suspendisse pharetra porta eros ac laoreet.',
            fullName: 'Mary Smith',
            profileImage: new CustomFileUpload(
              undefined,
              'd15c5fb5-4d77-4742-9ffd-f391c109c197',
              '_profileTest.png',
              '0.03Mb',
              faceImg
            ),
            dateCreated: moment('2022-05-09T12:56:14').toDate()
          },
          {
            id: 26,

            content: '       ',
            fullName: 'Mary Smith',
            profileImage: new CustomFileUpload(
              undefined,
              'd15c5fb5-4d77-4742-9ffd-f391c109c197',
              '_profileTest.png',
              '0.03Mb',
              faceImg
            ),
            dateCreated: moment('2022-05-09T14:26:26').toDate()
          }
        ]
      },
      {
        id: 19,
        content:
          'Phasellus porttitor iaculis diam, luctus dictum nisl tincidunt condimentum',
        fullName: 'Mary Smith',
        profileImage: new CustomFileUpload(
          undefined,
          'd15c5fb5-4d77-4742-9ffd-f391c109c197',
          '_profileTest.png',
          '0.03Mb',
          faceImg
        ),
        dateCreated: moment('2022-05-09T12:56:03').toDate(),
        replies: [
          {
            id: 22,
            content: 'Aliquam sit amet enim ex.',
            fullName: 'Mary Smith',
            profileImage: new CustomFileUpload(
              undefined,
              'd15c5fb5-4d77-4742-9ffd-f391c109c197',
              '_profileTest.png',
              '0.03Mb',
              faceImg
            ),
            dateCreated: moment('2022-05-09T12:56:38').toDate()
          },
          {
            id: 23,
            content:
              'Donec quis egestas enim. Integer fermentum sit amet risus a laoreet. Nunc justo lacus, ultrices nec purus nec, interdum aliquet magna.',
            fullName: 'Mary Smith',
            profileImage: new CustomFileUpload(
              undefined,
              'd15c5fb5-4d77-4742-9ffd-f391c109c197',
              '_profileTest.png',
              '0.03Mb',
              faceImg
            ),
            dateCreated: moment('2022-05-09T12:56:47').toDate()
          },
          {
            id: 25,
            content: 'ASFSDAF',
            fullName: 'Violet Zellweger',
            profileImage: new CustomFileUpload(
              undefined,
              'a55c8a41-44c5-450d-95c8-1752d46e00e5',
              '_profileTest3.png',
              '0.01Mb',
              faceImg3
            ),
            dateCreated: moment('2022-05-09T14:24:37').toDate()
          },
          {
            id: 27,
            content: '',
            fullName: 'Mary Smith',
            profileImage: new CustomFileUpload(
              undefined,
              'd15c5fb5-4d77-4742-9ffd-f391c109c197',
              '_profileTest.png',
              '0.03Mb',
              faceImg
            ),
            dateCreated: moment('2022-05-09T14:26:41').toDate()
          }
        ]
      },
      {
        id: 21,

        content: 'Suspendisse posuere interdum diam in accumsan.',
        fullName: 'Mary Smith',
        profileImage: new CustomFileUpload(
          undefined,
          'd15c5fb5-4d77-4742-9ffd-f391c109c197',
          '_profileTest.png',
          '0.03Mb',
          faceImg
        ),
        dateCreated: moment('2022-05-09T12:56:32').toDate()
      },
      {
        id: 24,

        content: 'sadfasd',
        fullName: 'Mary Smith',
        profileImage: new CustomFileUpload(
          undefined,
          'd15c5fb5-4d77-4742-9ffd-f391c109c197',
          '_profileTest.png',
          '0.03Mb',
          faceImg
        ),
        dateCreated: moment('2022-05-09T13:17:00').toDate()
      }
    ],
    fullName: 'Jay Smith',
    profileImage: new CustomFileUpload(
      undefined,
      '06137a46-1180-4e1e-8d83-071b9659ab87',
      '_profileTest2.png',
      '0.01Mb',
      faceImg2
    ),
    id: 3,
    content: 'asdfsadfasdf',
    image: new CustomFileUpload(
      undefined,
      '445345f9-4e03-40ad-aa9c-ad67c0159ad2',
      '_example2.jpg',
      '0.12Mb',
      uploadedImg
    )
  },
  {
    authorId: 'abc',
    authorType: 'Athlete',
    createDate: moment('2022-05-04T20:16:08').toDate(),
    originalPost: {
      authorId: 'abc',
      authorType: 'Athlete',
      createDate: moment('2022-05-04T20:16:08').toDate(),
      originalPost: null,
      comments: [],
      fullName: 'originalPost Jay Smith',
      profileImage: new CustomFileUpload(
        undefined,
        '06137a46-1180-4e1e-8d83-071b9659ab87',
        '_profileTest2.png',
        '0.01Mb',
        faceImg2
      ),
      id: 2,
      content: 'originalPost asdfasf',
      image: new CustomFileUpload(
        undefined,
        '5b55b031-1cf5-47d0-ba31-3ded63536252',
        '_example1.jpg',
        '0.12Mb',
        uploadedImg2
      )
    },
    comments: [],
    fullName: 'Jay Smith',
    profileImage: new CustomFileUpload(
      undefined,
      '06137a46-1180-4e1e-8d83-071b9659ab87',
      '_profileTest2.png',
      '0.01Mb',
      faceImg2
    ),
    id: 2,
    content: 'asdfasf',
    image: new CustomFileUpload(
      undefined,
      '5b55b031-1cf5-47d0-ba31-3ded63536252',
      '_example1.jpg',
      '0.12Mb',
      uploadedImg2
    )
  }
];

export const mocked_postCommentViewModel_list: Array<PostCommentViewModel> = [];
