





















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Error from '@/components/Error.vue';
import { Validation } from 'vuelidate';
@Component({
  inheritAttrs: false,
  components: {
    Error
  }
})
export default class TextBox extends Vue {
  @Prop() private value!: string;
  @Prop() private id?: string;
  @Prop() private label!: string;
  @Prop() private tipText?: string;
  @Prop() private prepend?: string;
  @Prop({ default: () => ({}) }) private labelClass!: {
    [prop: string]: string;
  };
  @Prop({ default: () => ({}) }) private innerClasses!: {
    [prop: string]: string;
  };
  @Prop({ default: false }) private required?: boolean;
  @Prop({ default: 'text' }) private type!: string;
  @Prop({ default: null }) private showError?:
    | Validation
    | boolean
    | null
    | undefined;
  @Prop() private errorLabel?: string | { [key: string]: string }[] | null;
  @Prop() private forcedErrorMessage?: string | null | undefined;
  @Prop({ default: true }) private addFormControlClass?: boolean;

  addClass = false;

  $refs!: {
    txtInput: HTMLInputElement;
  };

  @Emit('keyup')
  enterKeyHandler(event: any) {
    return event;
  }

  @Emit('input')
  sendInput(event: any): string {
    return event.target.value;
  }
  showHideError(show: boolean) {
    this.addClass = show;
  }

  focus() {
    this.$refs.txtInput.focus();
  }
}
