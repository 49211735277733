import CustomFileUpload from './customFileUpload';
import LoginTypeEnum from './loginTypeEnum';

export default interface LoginResponse {
  success: boolean;
  hasCompletedProfile?: boolean | null;
  theme?: string | null;
  nameToBeDisplayedInPortal?: string | null;
  fullName?: string | null;
  agentParentFirstName?: string | null;
  profileImage?: CustomFileUpload | null;
  loginType?: number | null;
  errorMessage?: string | null;
}

export const mocked_loginAthlete_Success: LoginResponse = {
  success: true,
  hasCompletedProfile: true,
  theme: 'light',
  nameToBeDisplayedInPortal: 'Mary',
  profileImage: new CustomFileUpload(
    undefined,
    undefined,
    undefined,
    undefined,
    'https://media.glamour.com/photos/5a425fd3b6bcee68da9f86f8/1:1/w_120,c_limit/best-face-oil.png'
  ),
  loginType: LoginTypeEnum.Athlete
};

export const mocked_loginAthleteIncomplete_Success: LoginResponse = {
  success: true,
  hasCompletedProfile: false,
  theme: 'light',
  nameToBeDisplayedInPortal: 'Mary',
  profileImage: new CustomFileUpload(
    undefined,
    undefined,
    undefined,
    undefined,
    'https://media.glamour.com/photos/5a425fd3b6bcee68da9f86f8/1:1/w_120,c_limit/best-face-oil.png'
  ),
  loginType: LoginTypeEnum.Athlete
};

export const mocked_loginSponsor_Success: LoginResponse = {
  success: true,
  hasCompletedProfile: true,
  theme: 'light',
  nameToBeDisplayedInPortal: 'Tom',
  profileImage: new CustomFileUpload(
    undefined,
    undefined,
    undefined,
    undefined,
    'https://pbs.twimg.com/profile_images/464730364993232897/AquKUVAk_400x400.png'
  ),
  loginType: LoginTypeEnum.Sponsor
};

export const mocked_loginSponsorIncomplete_Success: LoginResponse = {
  success: true,
  hasCompletedProfile: false,
  theme: 'light',
  nameToBeDisplayedInPortal: 'Tom',
  profileImage: new CustomFileUpload(
    undefined,
    undefined,
    undefined,
    undefined,
    'https://pbs.twimg.com/profile_images/464730364993232897/AquKUVAk_400x400.png'
  ),
  loginType: LoginTypeEnum.Sponsor
};

export const mocked_loginFan_Success: LoginResponse = {
  success: true,
  hasCompletedProfile: true,
  theme: 'light',
  nameToBeDisplayedInPortal: 'Darren',
  profileImage: new CustomFileUpload(
    undefined,
    undefined,
    undefined,
    undefined,
    'https://pbs.twimg.com/profile_images/464730364993232897/AquKUVAk_400x400.png'
  ),
  loginType: LoginTypeEnum.Fan
};

export const mocked_loginBackoffice_Success: LoginResponse = {
  success: true,
  nameToBeDisplayedInPortal: 'Dev',
  profileImage: new CustomFileUpload(
    undefined,
    undefined,
    undefined,
    undefined,
    'https://media.glamour.com/photos/5a425fd3b6bcee68da9f86f8/1:1/w_120,c_limit/best-face-oil.png'
  ),
  loginType: LoginTypeEnum.Backoffice
};

export const mocked_login_Fail: LoginResponse = {
  success: false,
  errorMessage: 'Invalid credentials.'
};

export const mocked_SuccessTrueOnly = {
  success: true
};

export const mocked_SuccessFalseOnly = {
  success: false
};
