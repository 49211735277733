import DropdownOption from '@/models/dropdownOption';
import moment from 'moment';

export const customValidateDateFormat = 'DD/MM/YYYY';
export const invalidDateMomentValue = moment('0001-01-01');
export const localeCode = 'en-GB';

export function getValidNumberOrNanOrNull(
  value: string | number | null | undefined
): number | null {
  if (value === null || value === undefined) {
    return null;
  }

  if (typeof value === 'number') {
    return value;
  }

  if (value === '') {
    return null;
  }

  value = value.replace(' ', '').trim();

  if (!/^[+-]?£?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$/i.test(value)) {
    return Number.NaN;
  }

  return Number(value.replace(/[£,]/gi, '')).valueOf();
}

export function getValidDateOrNull(value: string | Date): moment.Moment | null {
  if (value instanceof String) {
    const mDate = moment(value, customValidateDateFormat);
    if (mDate.format(customValidateDateFormat) === value) {
      return mDate;
    }
  } else {
    const mDate = moment(value);
    if (mDate.isValid() && mDate.isAfter(invalidDateMomentValue)) {
      return mDate;
    }
  }

  return null;
}

export function dropdownGetItemText(
  value: any | null | undefined,
  options: Array<DropdownOption>
): string {
  if (value === null || value === undefined) {
    return '';
  }

  return options.find((x) => x.value === value)?.text || '';
}

export function dropdownGetItemsText(
  value: any[] | null | undefined,
  options: Array<DropdownOption>
): Array<string> {
  if (value === null || value === undefined) {
    return [];
  }

  return (
    options.filter((x) => value.includes(x.value))?.map((x) => x.text) || []
  );
}

export function escapeHtmlAndAddLinebreak(
  value: string | null | undefined
): string {
  return (
    value
      ?.replace(/&/g, '&amp;')
      ?.replace(/</g, '&lt;')
      ?.replace(/>/g, '&gt;')
      ?.replace(/"/g, '&quot;')
      ?.replace(/'/g, '&#039;')
      ?.replace(/\r\n|\r|\n/gi, '<br />') || ''
  );
}

export function cloneArray<T>(array?: Array<T> | null): Array<T> {
  const oRet = new Array<T>();

  if (array !== undefined && array !== null && array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      oRet.push(array[i]);
    }
  }

  return oRet;
}

export function cloneArrayOfObjects<T>(array?: Array<T> | null): Array<T> {
  const oRet = new Array<T>();

  if (array !== undefined && array !== null && array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      oRet.push({ ...array[i] });
    }
  }

  return oRet;
}

export function cloneArrayOfObjectsAndReduce<T, U>(
  array: Array<T> | null | undefined,
  reduce: (item: T) => U
): Array<U> {
  const oRet = new Array<U>();

  if (array !== undefined && array !== null && array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      oRet.push(reduce(array[i]));
    }
  }

  return oRet;
}

export function loadExternalScript(scriptUrl: string) {
  return new Promise((resolve) => {
    const scriptElement = document.createElement('script');
    scriptElement.src = scriptUrl;
    scriptElement.onload = resolve;
    document.body.appendChild(scriptElement);
  });
}
