import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import AthleteProfileViewModel from '@/models/athlete/profileViewModel';
import AthleteProfileWithHeroViewModel from '@/models/athlete/profileWithHeroViewModel';
import { athleteProfileService } from '@/services/athlete/profile.service';
import SponsorProfileViewModel from '@/models/sponsor/profileViewModel';
import { sponsorProfileService } from '@/services/sponsor/profile.service';
import FanProfileViewModel from '@/models/fan/profileViewModel';
import { fanProfileService } from '@/services/fan/profile.service';
import CustomFileUpload from '@/models/customFileUpload';
import { backofficePortalUserService } from '@/services/backoffice/portalUser.service';

@Module({ namespaced: true, name: 'ProfileModule' })
export default class ProfileModule extends VuexModule {
  vAthleteProfile: AthleteProfileWithHeroViewModel | null = null;
  vSponsorProfile: SponsorProfileViewModel | null = null;
  vFanProfile: FanProfileViewModel | null = null;

  get athleteProfile() {
    return this.vAthleteProfile;
  }

  get sponsorProfile() {
    return this.vSponsorProfile;
  }

  get fanProfile() {
    return this.vFanProfile;
  }

  @Mutation
  clear() {
    this.vAthleteProfile = null;
    this.vSponsorProfile = null;
    this.vFanProfile = null;
  }

  @Mutation
  mSaveAthlete(payload: AthleteProfileViewModel | null) {
    const newProfile = Object.assign(
      this.vAthleteProfile || { heroImage: null },
      payload,
      {
        sendHttpSave: undefined
      }
    );

    if (newProfile.heroImage) {
      newProfile.heroImage = new CustomFileUpload(
        undefined,
        newProfile.heroImage.guid,
        newProfile.heroImage.fileName,
        newProfile.heroImage.fileSize,
        newProfile.heroImage.src
      );
    }

    this.vAthleteProfile = newProfile;
  }

  @Mutation
  mSaveSponsor(payload: SponsorProfileViewModel | null) {
    this.vSponsorProfile = Object.assign(this.vSponsorProfile || {}, payload, {
      sendHttpSave: undefined
    });
  }

  @Mutation
  mSaveFan(payload: FanProfileViewModel | null) {
    this.vFanProfile = Object.assign(this.vFanProfile || {}, payload, {
      sendHttpSave: undefined
    });
  }

  @Action({ rawError: true })
  async loadAthleteProfileAsync(payload: {
    addGroupName: boolean;
    backoffice: boolean;
    id?: string | null;
  }) {
    let profile: AthleteProfileWithHeroViewModel | null = null;
    if (payload.backoffice) {
      profile = await backofficePortalUserService.loadAthleteProfileAsync(
        payload.id || '',
        payload.addGroupName
      );
    } else {
      profile = await athleteProfileService.getProfileAsync(
        payload.addGroupName
      );
    }

    this.context.commit('mSaveAthlete', profile);
  }

  @Action({ rawError: true })
  async loadProfileAsync(addGroupName = true) {
    let profile: AthleteProfileViewModel | null = null;
    profile = await athleteProfileService.getProfileAsync(addGroupName);
    this.context.commit('mSaveAthlete', profile);
  }

  @Action({ rawError: true })
  async loadSponsorProfileAsync(payload: {
    addGroupName: boolean;
    backoffice: boolean;
    id?: string | null;
  }) {
    let profile: SponsorProfileViewModel | null = null;

    if (payload.backoffice) {
      profile = await backofficePortalUserService.loadProfileAsync(
        payload.id || '',
        payload.addGroupName
      );
    } else {
      profile = await sponsorProfileService.getProfileAsync(
        payload.addGroupName
      );
    }
    this.context.commit('mSaveSponsor', profile);
  }

  @Action({ rawError: true })
  async loadFanProfileAsync(payload: {
    addGroupName: boolean;
    backoffice: boolean;
    id?: string | null;
  }) {
    let profile: FanProfileViewModel | null = null;
    if (payload.backoffice) {
      profile = await backofficePortalUserService.loadFanProfileAsync(
        payload.id || '',
        payload.addGroupName
      );
    } else {
      profile = await fanProfileService.getProfileAsync(payload.addGroupName);
    }

    this.context.commit('mSaveFan', profile);
  }

  @Action({ rawError: true })
  async persistAthleteAsync() {
    await athleteProfileService.saveProfileAsync(this.vAthleteProfile);
  }

  @Action({ rawError: true })
  async persistSponsorAsync() {
    await sponsorProfileService.saveProfileAsync(this.vSponsorProfile);
  }

  @Action({ rawError: true })
  async saveAthleteAsync(
    model:
      | (AthleteProfileViewModel & {
          id?: string | null;
          backoffice?: boolean | null;
          sendHttpSave?: boolean;
        })
      | null
  ) {
    if (model?.sendHttpSave) {
      const toBeSaved = Object.assign({}, this.vAthleteProfile, model, {
        sendHttpSave: undefined
      });
      if (model.backoffice) {
        await backofficePortalUserService.saveAthleteProfileAsync(
          model.id || '',
          toBeSaved
        );
      } else {
        await athleteProfileService.saveProfileAsync(toBeSaved);
      }
    }
    this.context.commit('mSaveAthlete', model);
  }

  @Action({ rawError: true })
  async saveSponsorAsync(
    model:
      | (SponsorProfileViewModel & {
          id?: string | null;
          backoffice: boolean;
          sendHttpSave?: boolean;
        })
      | null
  ) {
    this.context.commit('mSaveSponsor', model);
    if (model?.sendHttpSave) {
      model.sendHttpSave = undefined;

      if (model.backoffice) {
        await backofficePortalUserService.saveSponsorProfileAsync(
          model.id || '',
          this.vSponsorProfile
        );
      } else {
        await sponsorProfileService.saveProfileAsync(this.vSponsorProfile);
      }
    }
  }

  @Action({ rawError: true })
  async saveFanAsync(
    model:
      | (FanProfileViewModel & {
          id?: string | null;
          backoffice?: boolean | null;
          sendHttpSave?: boolean;
        })
      | null
  ) {
    this.context.commit('mSaveFan', model);
    if (model?.sendHttpSave) {
      model.sendHttpSave = undefined;
      if (model.backoffice) {
        await backofficePortalUserService.saveFanProfileAsync(
          model.id || '',
          this.vFanProfile
        );
      } else {
        await fanProfileService.saveProfileAsync(this.vFanProfile);
      }
    }
  }

  @Action({ rawError: true })
  async submitAthleteAsync() {
    await athleteProfileService.submitAsync(this.vAthleteProfile);
    this.context.commit(
      'AuthModule/setNameToBeDisplayedInPortal',
      { nameToBeDisplayedInPortal: this.vAthleteProfile?.firstName },
      {
        root: true
      }
    );
  }

  @Action({ rawError: true })
  async submitSponsorAsync() {
    await sponsorProfileService.submitAsync(this.vSponsorProfile);
    this.context.commit(
      'AuthModule/setNameToBeDisplayedInPortal',
      { nameToBeDisplayedInPortal: this.vSponsorProfile?.firstName },
      {
        root: true
      }
    );
  }

  // @Action({ rawError: true })
  // async submitFanAsync() {
  //   await fanProfileService.submitAsync(this.vFanProfile);
  //   this.context.commit(
  //     'AuthModule/setNameToBeDisplayedInPortal',
  //     this.vFanProfile?.firstName,
  //     {
  //       root: true
  //     }
  //   );
  // }
}
