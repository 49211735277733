























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Validation } from 'vuelidate';

@Component
export default class Error extends Vue {
  @Prop({ default: null }) private error?:
    | Validation
    | boolean
    | null
    | undefined;
  @Prop() private errorMessage?: string | { [key: string]: string } | null;
  @Prop() private forcedErrorMessage?: string | null | undefined;

  get showErrorMessageText(): boolean {
    let oRet = false;
    if (this.isValidation(this.error)) {
      oRet = this.error.$error;
    } else {
      oRet = !!this.error;
    }
    if (this.forcedErrorMessage) {
      oRet = true;
    }
    this.$emit('showHideError', oRet);
    return oRet;
  }

  get isErrorMessageAnObject(): boolean {
    return typeof this.errorMessage === 'object';
  }

  get possibleErrors(): string[] {
    let result: string[] = [];

    if (this.isValidation(this.error)) {
      const innerResult = this.iterateThroughObject('', this.error);
      result = result.concat(innerResult);
    }

    return result;
  }

  get errorMessageOrDefault() {
    if (typeof this.errorMessage !== 'object') {
      return this.errorMessage;
    } else {
      if (this.isValidation(this.error)) {
        return this.error;
      } else {
        return 'Not supported: If property "error" is NOT an object then property "errorMessage" cannot be an object';
      }
    }
  }

  getErrorMessageByValidationType(type: string): string {
    if (typeof this.errorMessage === 'object') {
      const result = (this.errorMessage as any)[type];
      if (result === undefined) {
        return `ERROR: There is no validation message for validation type '${type}'`;
      } else {
        return result;
      }
    }
    return 'Not supported';
  }

  private iterateThroughObject(
    prefix: string,
    validation: Validation
  ): Array<string> {
    let result: Array<string> = [];
    const keys = Object.keys(validation) || [];
    for (let i = 0; i < keys.length; i++) {
      const sKey = keys[i] as string;
      if (sKey && sKey !== '' && sKey.substring(0, 1) !== '$') {
        const newPrefix = (prefix.length ? prefix + '.' : '') + sKey;
        if (this.isValidation((validation as any)[sKey])) {
          const innerResult = this.iterateThroughObject(
            newPrefix,
            (validation as any)[sKey]
          );
          result = result.concat(innerResult);
        } else {
          if (!(validation as any)[sKey]) {
            result.push(newPrefix);
          }
        }
      }
    }
    return result;
  }

  private isValidation(arg: any): arg is Validation {
    if (arg) {
      return (arg as Validation).$error !== undefined;
    } else {
      return false;
    }
  }
}
