import moment from 'moment';
import CustomFileUpload from '../../customFileUpload';
import LoginTypeEnum from '../../loginTypeEnum';
import PagedList from '../../pagedList';

export default interface PortalUserListItemViewModel {
  id: string;
  name: string;
  companyName?: string | null;
  email: string;
  loginType: LoginTypeEnum;
  loginTypeName: string;
  profileImage: CustomFileUpload;
  lastLogin?: Date | null;
  status: string;
  emailValidated: string;
}

export const mocked_portalUserListItemViewModel_list: PagedList<PortalUserListItemViewModel> =
  {
    currentPage: 1,
    totalItemsCount: 3,
    data: [
      {
        id: 'UserId1',
        name: 'User1 Surname1',
        email: 'user1@anewicon.com',
        loginType: LoginTypeEnum.Athlete,
        loginTypeName: 'Athlete',
        profileImage: new CustomFileUpload(
          undefined,
          undefined,
          undefined,
          undefined,
          'https://media.glamour.com/photos/5a425fd3b6bcee68da9f86f8/1:1/w_120,c_limit/best-face-oil.png'
        ),
        lastLogin: moment().subtract(2, 'hour').toDate(),
        status: 'Active',
        emailValidated: 'Yes'
      },
      {
        id: 'UserId2',
        name: 'User2 Surname2',
        companyName: 'Adidas',
        email: 'user2@anewicon.com',
        loginType: LoginTypeEnum.Sponsor,
        loginTypeName: 'Sponsor',
        profileImage: new CustomFileUpload(
          undefined,
          undefined,
          undefined,
          undefined,
          'https://media.glamour.com/photos/5a425fd3b6bcee68da9f86f8/1:1/w_120,c_limit/best-face-oil.png'
        ),
        lastLogin: moment()
          .subtract(1, 'day')
          .subtract(4, 'hour')
          .add(11, 'minute')
          .toDate(),
        status: 'Active',
        emailValidated: 'No'
      },
      {
        id: 'UserId3',
        name: 'User3 Surname3',
        email: 'user3@anewicon.com',
        loginType: LoginTypeEnum.Athlete,
        loginTypeName: 'Athlete',
        profileImage: new CustomFileUpload(
          undefined,
          undefined,
          undefined,
          undefined,
          'https://media.glamour.com/photos/5a425fd3b6bcee68da9f86f8/1:1/w_120,c_limit/best-face-oil.png'
        ),
        // lastLogin: null,
        status: 'Active',
        emailValidated: 'Yes'
      }
    ]
  };
