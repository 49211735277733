import VueRouter from 'vue-router';
import { Dictionary } from 'vue-router/types/router';

export class RouterHelper {
  private router: VueRouter;

  constructor(router: VueRouter) {
    this.router = router;
  }

  private static instance: RouterHelper;

  public static Instance(router: VueRouter) {
    return this.instance || new this(router);
  }

  goToLogin(query?: Dictionary<string | (string | null)[] | null | undefined>) {
    this.pushToRoute('login', query);
  }

  goToBackofficeLogin(
    query?: Dictionary<string | (string | null)[] | null | undefined>
  ) {
    this.pushToRoute('backofficeLogin', query);
  }

  goToDashboard() {
    this.pushToRoute('dashboard');
  }

  goToBackofficeDashboard() {
    this.pushToRoute('backofficeDashboard');
  }

  pushToRoute(
    routeName: string,
    query?: Dictionary<string | (string | null)[] | null | undefined>,
    params?: Dictionary<string>
  ) {
    this.router.push({
      name: routeName,
      query: query,
      params: params
    });
  }

  openNewWindow(
    routeName: string,
    query?: Dictionary<string | (string | null)[] | null | undefined>,
    params?: Dictionary<string>
  ) {
    const url = this.router.resolve({
      name: routeName,
      query: query,
      params: params
    });

    window.open(url.href);
  }
}

export function routerHelper(router: VueRouter) {
  return RouterHelper.Instance(router);
}
